import React from "react";
import { Link } from "react-router-dom";
import { CardBody, Col, Row } from "reactstrap";
import { imageUrl, toArray } from "../../helpers/common";
import userImage6 from "../../assets/images/avatar.png";
import AddToBookmarkCandidate from "../../helpers/Common/AddToBookmarkCandidate";

const CandidateRow = ({ details }) => {
  const additionalFields = details?.additional_fields
    ? JSON.parse(details.additional_fields)
    : {};

  const skills = additionalFields ? toArray(additionalFields?.skills, ",") : [];
  const profilePicUrl = imageUrl(
    additionalFields?.profile_picture,
    "customers"
  );

  const candidateDetailUrl = `/candidate/details/${details?.id}`;

  return (
    <div>
      <div className={"candidate-list-box card mt-4"}>
        <CardBody className="p-4">
          <Row className="align-items-center">
            <Col xl={1} lg={2}>
              <div className="candidate-list-images">
                <Link to={candidateDetailUrl}>
                  <img
                    src={
                      additionalFields?.profile_picture
                        ? profilePicUrl
                        : userImage6
                    }
                    alt=""
                    className="avatar-md img-thumbnail rounded-circle"
                  />
                </Link>
              </div>
            </Col>
            <Col xl={9} lg={8}>
              <div className="candidate-list-content mt-3 mt-lg-0">
                <h5 className="fs-19 mb-0">
                  <Link
                    to={candidateDetailUrl}
                    className="primary-link text-capitalize"
                  >
                    {details?.name || details?.username}
                  </Link>
                </h5>
                <p className="text-muted mb-1" style={{ marginLeft: 2 }}>
                  {additionalFields?.designation}
                </p>
                <ul className="list-inline mb-0 text-muted">
                  <li className="list-inline-item mb-2">
                    <i className="mdi mdi-map-marker"></i>{" "}
                    {additionalFields?.location
                      ? additionalFields?.location
                      : "India"}
                  </li>
                </ul>

                {skills?.length > 0 && (
                  <div className="mb-3 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                    {skills?.map((skill, key) => (
                      <span
                        className="badge bg-soft-secondary fs-14 mt-1"
                        key={key}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </Col>

            <Col xl={2} lg={2}>
              {/* <div className="d-flex justify-content-lg-end me-lg-n2 mt-3 mt-lg-5 ">
                <Button color="primary" size="md">
                  Hire Now
                </Button>
              </div> */}
            </Col>
          </Row>

          <AddToBookmarkCandidate candidateId={details?.id} />
        </CardBody>
      </div>
    </div>
  );
};

export default CandidateRow;
