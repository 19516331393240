import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  authKey_local_storage_key,
  convertToSlug,
  getCompanyLogo,
  getJobExperiences,
  getKeywords,
  getSalaryAmount,
} from "../common";
import AddToBookmarkJob from "./AddToBookmarkJob";
import ApplyJobModal from "./ApplyJobModal";

const SingleJobItem = ({ jobDetails }) => {
  //Apply for Job Model
  const [applyJobModal, setApplyJobModal] = useState(false);
  const [currJob, setCurrJob] = useState({});

  const authToken = localStorage.getItem(authKey_local_storage_key);
  const navigate = useNavigate()
  
  const openApplyJobModal = (jobDeatails) => {
    if (authToken) {
      setApplyJobModal(!applyJobModal);
      setCurrJob(jobDeatails);
    } else {
      navigate('/signin')
    }
  };

  const customer = useSelector((state) => state.customer.profile);

  const companyDetails = jobDetails.company;
  const jobDetailsUrl = `/job/details/${jobDetails.id}/${convertToSlug(
    jobDetails.job_title
  )}`;


  const candidateReducer = useSelector((state) => state.candidate);
  const appliedJobs = candidateReducer.jobApplications;

  const isAppliedJob = appliedJobs?.find(
    (item) => item?.job?.id === jobDetails?.id
  );


  return (
    <>
      <div
        className={
          jobDetails?.addclassNameBookmark === true
            ? "job-box bookmark-post card mt-4"
            : "job-box card mt-4"
        }
      >
        <div className="p-4">
          <Row>
            <Col lg={2} className="text-lg-center">
              <Link to={jobDetailsUrl}>
                <img
                  src={getCompanyLogo(companyDetails?.logo)}
                  alt={jobDetails.job_title}
                  className="img-fluid rounded-3 comp-logo"
                />
              </Link>
            </Col>
            <Col lg={10}>
              <div className="mt-3 mt-lg-0">
                <h5 className="fs-17 mb-1">
                  <Link to={jobDetailsUrl} className="text-dark">
                    {jobDetails.job_title}
                  </Link>{" "}
                  <small className="text-muted fw-normal">
                    ({getJobExperiences(jobDetails, "Yrs Exp.")})
                  </small>
                </h5>
                <ul className="list-inline mb-0">
                  {companyDetails && companyDetails?.name && (
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        <i className="mdi mdi-office-building"></i>{" "}
                        {companyDetails?.name}
                      </p>
                    </li>
                  )}
                  <li className="list-inline-item">
                    <p className="text-muted fs-14 mb-0">
                      <i className="mdi mdi-map-marker"></i>
                      {jobDetails?.location?.name || "India"}
                    </p>
                  </li>
                  <li className="list-inline-item">
                    <p className="text-muted fs-14 mb-0">
                      {getSalaryAmount(jobDetails)}
                    </p>
                  </li>
                </ul>
                <div className="mt-2">
                  <span
                    className={
                      jobDetails?.job_type?.toLowerCase() === "full time"
                        ? "badge bg-soft-success fs-13 mt-1 me-1"
                        : jobDetails?.job_type?.toLowerCase() === "part time"
                          ? "badge bg-soft-danger fs-13 mt-1 me-1"
                          : jobDetails?.job_type?.toLowerCase() === "freelancer"
                            ? "badge bg-soft-purple fs-13 mt-1 me-1"
                            : jobDetails?.job_type?.toLowerCase() === "internship"
                              ? "badge bg-soft-blue fs-13 mt-1 me-1"
                              : "badge bg-soft-secondary fs-13 mt-1 me-1"
                    }
                  >
                    {jobDetails.job_type}
                  </span>

                  <span
                    className={
                      jobDetails?.job_position?.toLowerCase() === "fresher"
                        ? "badge bg-soft-blue fs-13 mt-1 me-1"
                        : jobDetails?.job_position?.toLowerCase() === "junior"
                          ? "badge bg-soft-pink fs-13 mt-1 me-1"
                          : jobDetails?.job_position?.toLowerCase() === "senior"
                            ? "badge bg-soft-info fs-13 mt-1 me-1"
                            : "badge bg-soft-pink fs-13 mt-1 me-1"
                    }
                  >
                    {jobDetails?.job_position}
                  </span>

                  {jobDetails?.job_priority?.toLowerCase() === "high" && (
                    <span className={`badge bg-soft-warning fs-13 mt-1`}>
                      Urgent
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {/* Bookmark button */}
          <AddToBookmarkJob
            size="sm"
            jobDetails={jobDetails}
            companyDetails={companyDetails}
          />
        </div>
        <div className="p-3 bg-light">
          <div className="row justify-content-between">
            <div className="col-md-8">
              <div>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <i className="uil uil-tag"></i> Keywords :
                  </li>

                  {getKeywords(jobDetails.search_keywords)}
                </ul>
              </div>
            </div>

            {customer?.customer_type !== 1 ? (
              <div className="col-md-3">
                <div className="text-md-end">
                  {isAppliedJob ?
                    <span style={{ color: 'green' }}>Already Applied <i className="mdi mdi-check"></i></span> :
                    <p
                      onClick={() => openApplyJobModal(jobDetails)}
                      className="primary-link link"
                    >
                      Apply Now <i className="mdi mdi-chevron-double-right"></i>
                    </p>
                  }
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <ApplyJobModal
        currJob={currJob}
        openApplyJobModal={openApplyJobModal}
        applyJobModal={applyJobModal}
      />
    </>
  );
};

export default SingleJobItem;
