import {
  GET_JOB_LOCATIONS_FAILURE,
  GET_JOB_LOCATIONS_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  data: [],
  error: null,
};

export default function jobLocations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_JOB_LOCATIONS_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_JOB_LOCATIONS_FAILURE:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
