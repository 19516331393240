import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  Input,
  Label,
  Col,
  Row,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { getCandidateQualifications } from "../../../../actions/customer";
import { createContent, updateContentsById } from "../../../../api/contents";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";
import YearOptions from "../../../../helpers/YearOptions";

const AddQualification = ({
  setOpenQualificationModal,
  openQualificationModal,
  modalType = "add",
  qualificationData,
}) => {
  const additional_fields = qualificationData?.additional_fields;

  const initialState = {
    title: "",
    university_college: "",
    start_year: "",
    end_year: "",
    details: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const triggerModal = () => setOpenQualificationModal(!openQualificationModal);

  const { profile } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const onChangeValue = (e, value) => {
    setFormData({ ...formData, [value]: e.target.value });
  };

  useEffect(() => {
    setFormData({
      title: additional_fields?.title || "",
      university_college: additional_fields?.university_college || "",
      start_year: additional_fields?.start_year || "",
      end_year: additional_fields?.end_year || "",
      details: additional_fields?.details || "",
    });
  }, [qualificationData, additional_fields]);

  const onQualificationSave = (e) => {
    e.preventDefault();

    if (!formData?.title) {
      setErrors({ title: "This is required field" });
      return;
    }

    if (!formData?.university_college) {
      setErrors({ university_college: "This is required field" });
      return;
    }

    if (!formData?.start_year) {
      setErrors({ start_year: "This is required field" });
      return;
    }

    if (!formData?.end_year) {
      setErrors({ end_year: "This is required field" });
      return;
    }

    if (formData?.start_year > formData?.end_year) {
      setErrors({
        end_year: "The end year can't be earlier than the start year.",
      });
      return;
    }

    if (!formData?.details) {
      setErrors({ details: "This is required field" });
      return;
    }

    setErrors(null);

    setLoading(true);

    const payload = {
      type: "candidate_qualifications",
      AdditionalFields: {
        ...formData,
        candidate_id: profile?.id?.toString(),
      },
    };

    if (modalType === "add") {
      createContent(payload)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.id) {
            triggerModal();
            CustomSnackbar.success("Qualification added successfully.");
            setFormData(initialState);

            dispatch(getCandidateQualifications(profile.id));
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    } else {
      updateContentsById(qualificationData?.id, payload)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.id) {
            triggerModal();
            CustomSnackbar.success("Qualification updated successfully.");
            setFormData(initialState);

            dispatch(getCandidateQualifications(profile.id));
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={openQualificationModal} toggle={triggerModal} centered>
            <ModalBody className="modal-body p-4">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {modalType === "add" ? "Add" : "Update"} Qualification
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={triggerModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <form onSubmit={onQualificationSave}>
                <div className="mb-3">
                  <Label for="positionControlInput" className="form-label">
                    Title *
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="positionControlInput"
                    placeholder="Enter your course name or title"
                    value={formData.title}
                    onChange={(e) => onChangeValue(e, "title")}
                    invalid={errors?.title ? true : false}
                  />
                  <FormFeedback>{errors?.title}</FormFeedback>
                </div>

                <div className="mb-3">
                  <Label for="companyControlInput2" className="form-label">
                    University / College *
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="companyControlInput2"
                    placeholder="Enter your university or college name"
                    value={formData.university_college}
                    onChange={(e) => onChangeValue(e, "university_college")}
                    invalid={errors?.university_college ? true : false}
                  />
                  <FormFeedback>{errors?.university_college}</FormFeedback>
                </div>

                <Row>
                  <Col md={6} className="mb-3">
                    <Label for="select" className="form-label">
                      Start year *
                    </Label>
                    <Input
                      type="select"
                      className="form-select"
                      name="choices-year"
                      id="choices-year"
                      aria-label="Default select example"
                      value={formData?.start_year}
                      onChange={(e) => onChangeValue(e, "start_year")}
                      invalid={errors?.start_year ? true : false}
                    >
                      <YearOptions />
                    </Input>
                    <FormFeedback>{errors?.start_year}</FormFeedback>
                  </Col>

                  <Col md={6} className=" mb-3">
                    <Label for="select" className="form-label">
                      End year *
                    </Label>
                    <Input
                      type="select"
                      className="form-select"
                      data-trigger
                      name="choices-year"
                      id="choices-year"
                      aria-label="Default select example"
                      value={formData?.end_year}
                      onChange={(e) => onChangeValue(e, "end_year")}
                      invalid={errors?.end_year ? true : false}
                    >
                      <YearOptions />
                    </Input>
                    <FormFeedback>{errors?.end_year}</FormFeedback>
                  </Col>
                </Row>

                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Details *
                  </Label>
                  <Input
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Describe your qualification"
                    value={formData.details}
                    onChange={(e) => onChangeValue(e, "details")}
                    type="textarea"
                    invalid={errors?.details ? true : false}
                  />
                  <FormFeedback>{errors?.details}</FormFeedback>
                </div>

                <div className="text-end">
                  <button
                    type="button"
                    onClick={triggerModal}
                    className="btn btn-danger me-2 "
                  >
                    Cancel
                  </button>

                  {loading ? (
                    <button type="button" className="btn btn-primary disabled">
                      Save <Spinner size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  )}
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </div>

      {/* <ConfirmationModal
        title="Delete qualification"
        message="Are you sure you want to delete this qualification ?"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
      /> */}
    </>
  );
};

export default AddQualification;
