import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { getNotifications } from "../../actions/notifications";
import { markNotificationsViewed } from "../../api/notifications";
import userImage2 from "../../assets/images/user/img-02.jpg";
import { getUnreadNotifications } from "../../helpers/common";

const Notification = () => {
  const allNotifications = useSelector((state) => state.notifications);
  const notificationsList = allNotifications.data;
  const dispatch = useDispatch();

  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const toggleDropdownNotifications = () =>
    setNotification((prevState) => !prevState);

  const unreadNotificationsCount =
    getUnreadNotifications(notificationsList)?.length;

  const onMarkViewed = (id) => {
    markNotificationsViewed(id)
      .then((res) => {
        console.log("res::", res);
        dispatch(getNotifications());
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => {
        toggleDropdownNotifications();
        dispatch(getNotifications());
      });
  };

  const NoNotificationsAvailable = () => {
    return (
      <div className="notification-wrapper dropdown-scroll">
        <div className="text-center my-5">
          <i className="mdi mdi-bell my-bell-icon"></i>
          <h6 className="">Notifications are not available yet.</h6>
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={notification}
      toggle={toggleDropdownNotifications}
      className="list-inline-item  me-4"
    >
      <DropdownToggle
        href="#"
        className="header-item noti-icon position-relative"
        id="notification"
        type="button"
        tag="a"
      >
        <i className="mdi mdi-bell fs-22"></i>
        <div className="count position-absolute">
          {unreadNotificationsCount}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-md dropdown-menu-end p-0"
        aria-labelledby="notification"
        end
      >
        <div className="notification-header border-bottom bg-light ">
          <h6 className="mb-1"> Notification </h6>
          <p className="text-muted fs-13 mb-0">
            You have {unreadNotificationsCount} unread Notification
            {`${unreadNotificationsCount > 1 ? "s" : ""}`}
          </p>
        </div>

        {notificationsList?.length > 0 ? (
          <div className="notification-wrapper dropdown-scroll">
            {notificationsList?.map((item) => (
              <Link
                to="#"
                onClick={() => onMarkViewed(item.id)}
                className="text-dark notification-item d-block active"
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    {item.icon ? (
                      <img
                        src={userImage2}
                        className="rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                    ) : (
                      <div className="avatar-xs bg-primary text-white rounded-circle text-center position-relative">
                        <i className="mdi mdi-bell"></i>
                        {item.is_viewed === 0 ? (
                          <div className="count unread position-absolute"></div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1 fs-14">
                      {item.message || "Unknown"}
                    </h6>
                    <p className="mb-0 fs-12 text-muted">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      <span>{moment(item?.created_at).fromNow()}</span>
                    </p>
                  </div>
                </div>
              </Link>
            ))}

            <>
              {/* <Link to="#" className="text-dark notification-item d-block active">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                  <i className="uil uil-user-check"></i>
                </div>
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1 fs-14">22 verified registrations</h6>
                <p className="mb-0 fs-12 text-muted">
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  <span>3 min ago</span>
                </p>
              </div>
            </div>
          </Link>
          <Link to="#" className="text-dark notification-item d-block">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <img
                  src={userImage2}
                  className="rounded-circle avatar-xs"
                  alt="user-pic"
                />
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1 fs-14">James Lemire</h6>
                <p className="text-muted fs-12 mb-0">
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  <span>15 min ago</span>
                </p>
              </div>
            </div>
          </Link>
          <Link to="#" className="text-dark notification-item d-block">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <img
                  src={jobImage4}
                  className="rounded-circle avatar-xs"
                  alt="user-pic"
                />
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1 fs-14">
                  Applications has been approved
                </h6>
                <p className="text-muted mb-0 fs-12">
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  <span>45 min ago</span>
                </p>
              </div>
            </div>
          </Link>
          <Link to="#" className="text-dark notification-item d-block">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <img
                  src={userImage1}
                  className="rounded-circle avatar-xs"
                  alt="user-pic"
                />
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1 fs-14">Kevin Stewart</h6>
                <p className="text-muted mb-0 fs-12">
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  <span>1 hour ago</span>
                </p>
              </div>
            </div>
          </Link>
          <Link to="#" className="text-dark notification-item d-block">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <img
                  src={jobImage}
                  className="rounded-circle avatar-xs"
                  alt="user-pic"
                />
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1 fs-15">Creative Agency</h6>
                <p className="text-muted mb-0 fs-12">
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  <span>2 hour ago</span>
                </p>
              </div>
            </div>
          </Link> */}
            </>
          </div>
        ) : (
          <NoNotificationsAvailable />
        )}

        <div className="notification-footer border-top text-center">
          <Link
            className="primary-link fs-13"
            to="/notifications"
            onClick={toggleDropdownNotifications}
          >
            <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
            <span>View More..</span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
