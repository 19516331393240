import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenus } from "../../actions/menus";
import { getBanners } from "../../actions/banners";
import { getContents } from "../../actions/contents";
import { getContentByName, getSettingByKey } from "../../helpers/common";
import parse from "html-react-parser";
import { getSettings } from "../../actions/settings";
import { getClientTestimonials } from "../../actions/clientTestimonials";
import { getCategory } from "../../actions/categories";
import { getFaqs } from "../../actions/faqs";
import {
  getEmployerCompanies,
  getEmployerJobs,
  getJobsApplications,
} from "../../actions/employer";
import { getJobs } from "../../actions/jobs";
import { getBookmarkJobs } from "../../actions/bookmarkJob";
import {
  getCandidateExperiences,
  getCandidateQualifications,
  updateProfile,
} from "../../actions/customer";
import { getBookmarkCandidates } from "../../actions/bookmarkCandidates";
import { getJobLocations } from "../../actions/jobLocations";
import { getNotifications } from "../../actions/notifications";
import { getCandiJobsApplications } from "../../actions/candidate";

const Footer = () => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer.profile);

  useEffect(() => {
    dispatch(getMenus());
    dispatch(getSettings());
    dispatch(getJobLocations());
    dispatch(getCategory());
    dispatch(getFaqs());
    dispatch(getJobs());
    dispatch(getBanners("featured_companies"));
    dispatch(getBanners("homepage_banners"));
    dispatch(getBanners("trusted_by_companies"));
    dispatch(getBanners("assets"));
    dispatch(getContents("partial_contents"));
    dispatch(getContents("job_locations"));
    dispatch(getContents("job_categories"));
    dispatch(getContents("aboutus_key_features"));
    dispatch(getContents("services"));
    dispatch(getContents("team"));
    dispatch(getClientTestimonials());
  }, [dispatch]);

  // only for logged in user
  useEffect(() => {
    if (customer.id) {
      dispatch(getNotifications());
    }
  }, [customer.id, dispatch]);

  // Employer
  useEffect(() => {
    if (customer.customer_type === 1) {
      dispatch(getEmployerJobs(customer.id));
      dispatch(getEmployerCompanies(customer.id));
      dispatch(getJobsApplications(customer.id));
      dispatch(getBookmarkCandidates(customer.id));
    }
  }, [customer.customer_type, customer.id, dispatch]);

  // Candidate
  useEffect(() => {
    if (customer.customer_type === 2) {
      dispatch(getCandiJobsApplications(customer.id));
      dispatch(getBookmarkJobs(customer.id));
      dispatch(getCandidateQualifications(customer.id));
      dispatch(getCandidateExperiences(customer.id));
    }
  }, [customer.customer_type, customer.id, dispatch]);

  const menus = useSelector((state) => state.menus.data);
  const content = useSelector((state) => state.contents["partial_contents"]);
  const foterDescription = getContentByName(
    content,
    "footer_description"
  )?.content;

  useEffect(() => {
    if (customer.customer_type === 2 && !customer.additional_fields) {
      dispatch(
        updateProfile({
          ...customer,
          id: customer.id,
          AdditionalFields: {
            profile_completion_percentage: '34',
          },
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.customer_type, dispatch]);

  // Menus
  const footerCompany = menus.filter(
    (menu) => menu.category === "footer_company"
  );
  const footerForJob = menus.filter(
    (menu) => menu.category === "footer_for_job"
  );
  const footerForCandidates = menus.filter(
    (menu) => menu.category === "footer_candidates"
  );
  const footerSupport = menus.filter(
    (menu) => menu.category === "footer_support"
  );
  const settings = useSelector((state) => state.settings.data);

  // Social Icons
  const facebookLink = getSettingByKey(
    settings,
    "social_links_facebook"
  )?.value;
  const twitterLink = getSettingByKey(settings, "social_links_twitter")?.value;
  const googleLink = getSettingByKey(settings, "social_links_google")?.value;
  const linkedinLink = getSettingByKey(
    settings,
    "social_links_linkedin"
  )?.value;

  // Copyright Text
  const copyrightText = getSettingByKey(settings, "copyright_text")?.value;

  const scrollTop = () => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <section className="bg-footer">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                <h4 className="text-white mb-4">Jobseekers Page</h4>
                <span className="text-white-50">
                  {parse(foterDescription ?? "")}
                </span>
                <p className="text-white mt-3">Follow Us on:</p>
                <ul className="footer-social-menu list-inline mb-0">
                  {/* {(footerIcons).map((footerIcondetails, key) => (
                                        <li className="list-inline-item" key={key}>
                                            <Link to="#"><i className={footerIcondetails.socialIcon}></i></Link>
                                        </li>
                                    ))} */}

                  {facebookLink && (
                    <li className="list-inline-item">
                      <a href={facebookLink} target="_blank" rel="noreferrer">
                        <i className="uil uil-facebook-f"></i>
                      </a>
                    </li>
                  )}

                  {linkedinLink && (
                    <li className="list-inline-item">
                      <a href={linkedinLink} target="_blank" rel="noreferrer">
                        <i className="uil uil-linkedin-alt"></i>
                      </a>
                    </li>
                  )}

                  {googleLink && (
                    <li className="list-inline-item">
                      <a href={googleLink} target="_blank" rel="noreferrer">
                        <i className="uil uil-google"></i>
                      </a>
                    </li>
                  )}

                  {twitterLink && (
                    <li className="list-inline-item">
                      <a href={twitterLink} target="_blank" rel="noreferrer">
                        <i className="uil uil-twitter"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className="footer-item mt-4 mt-lg-0">
                <p className="fs-16 text-white mb-4">Company</p>
                {footerCompany.map((menu, key) => (
                  <ul
                    className="list-unstyled footer-list mb-0"
                    key={"company-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className="mdi mdi-chevron-right"></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className="footer-item mt-4 mt-lg-0">
                <p className="fs-16 text-white mb-4">For Jobs</p>
                {footerForJob.map((menu, key) => (
                  <ul
                    className="list-unstyled footer-list mb-0"
                    key={"for-jobs-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className="mdi mdi-chevron-right"></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className="footer-item mt-4 mt-lg-0">
                <p className="fs-16 text-white mb-4">For Candidates</p>
                {footerForCandidates.map((menu, key) => (
                  <ul
                    className="list-unstyled footer-list mb-0"
                    key={"for-candidates-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className="mdi mdi-chevron-right"></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className="footer-item mt-4 mt-lg-0">
                <p className="fs-16 text-white mb-4">For Support</p>
                {footerSupport.map((menu, key) => (
                  <ul
                    className="list-unstyled footer-list mb-0"
                    key={"support-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className="mdi mdi-chevron-right"></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="footer-alt">
        <Container>
          <Row>
            <Col lg={12}>
              <p className="text-white-50 text-center mb-0">
                {parse(copyrightText ?? "")}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
