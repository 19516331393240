import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import ImageUploader from "../../../../helpers/Common/ImageUploader";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";
import noLogo from "../../../../assets/images/featured-job/no-logo.png";
import LocationOptions from "../../../../helpers/LocationOptions";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { scrollWindow } from "../../../../helpers/common";
import AddMoreDetails from "./AddMoreDetails";

const ManageCompanyForm = ({
  onSubmit,
  companyDetails,
  loading,
  formType = "add",
}) => {
  const [errors, setErrors] = useState({});
  const customer = useSelector((state) => state.customer.profile) || {};

  const working_days = `Monday => 9AM-5PM 
Tuesday => 9AM-5PM 
Wednesday => 9AM-5PM 
Thursday => 9AM-5PM 
Friday => 9AM-5PM 
Saturday => Close 
Sunday => Close`;

  const INITIAL_STATE = {
    logo: companyDetails?.logo || "",
    name: companyDetails?.name || "",
    company_details: companyDetails?.company_details || "",
    owner_name: companyDetails?.owner_name || "",
    total_employees: companyDetails?.total_employees || "",
    establishment_year: companyDetails?.establishment_year || "",
    working_days: companyDetails?.working_days || working_days,
    map_location: companyDetails?.map_location || "",
    contact_number: companyDetails?.contact_number || "",
    mobile_number: companyDetails?.mobile_number || "",
    email_address: companyDetails?.email_address || "",
    website_url: companyDetails?.website_url || "",
    company_address: companyDetails?.company_address || "",
    job_location_id: companyDetails?.job_location_id || "",
    gst_number: companyDetails?.gst_number || "",
  };
  const [formData, setFormData] = useState(INITIAL_STATE);

  const onChange = (e) => {
    if (e.target.name === Object.keys(errors)[0]) {
      setErrors({});
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    let err = {};
    setErrors(err);

    if (!formData.name) {
      CustomSnackbar.error("Please enter your company name");
      setErrors({ name: true });
      return;
    }

    if (!formData.email_address) {
      CustomSnackbar.error("Please enter email address");
      setErrors({ email_address: true });
      return;
    }

    if (!formData.mobile_number) {
      CustomSnackbar.error("Please enter mobile number");
      setErrors({ mobile_number: true });
      return;
    }

    if (!formData.job_location_id) {
      CustomSnackbar.error("Please select your company location");
      setErrors({ job_location_id: true });
      return;
    }

    if (!formData.company_address) {
      CustomSnackbar.error("Please enter your company address");
      setErrors({ company_address: true });
      return;
    }

    onSubmit({ ...formData, employer_id: customer.id });
  };

  const updateImageHandle = (imageName) => {
    setFormData({ ...formData, logo: imageName });
  };

  useEffect(() => {
    scrollWindow();
  }, []);

  return (
    <form onSubmit={onFormSubmit} className="job-post-form shadow mt-4">
      <div className="job-post-content box-shadow-md rounded-3 p-4">
        <ImageUploader
          uploadPath="/jobcompanies/attachment"
          assetType="jobcompanies"
          updateImageHandle={updateImageHandle}
          imageName={formData?.logo}
          placeholderImage={noLogo}
        />

        <Row className="row">
          <Col lg={6}>
            <div className="mb-4">
              <Label htmlFor="compName" className="form-label">
                Company Name *
              </Label>
              <Input
                type="text"
                value={formData?.name}
                className="form-control"
                autoComplete="off"
                id="compName"
                placeholder="Title"
                name="name"
                onChange={onChange}
                invalid={errors.name ? true : false}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="mb-4">
              <Label htmlFor="email" className="form-label">
                Email Address *
              </Label>
              <Input
                type="email"
                className="form-control"
                autoComplete="off"
                id="email"
                placeholder="Email Address"
                value={formData?.email_address}
                onChange={onChange}
                name="email_address"
                invalid={errors.email_address ? true : false}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="mb-4">
              <Label htmlFor="mobileNumber" className="form-label">
                Mobile Number *
              </Label>
              <Input
                type="number"
                className="form-control"
                autoComplete="off"
                id="mobileNumber"
                placeholder="Mobile Number"
                value={formData?.mobile_number}
                onChange={onChange}
                name="mobile_number"
                invalid={errors.mobile_number ? true : false}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="mb-4">
              <Label htmlFor="job_location_id" className="form-label">
                Company Location *
              </Label>
              <Input
                type="select"
                className="form-select"
                name="job_location_id"
                id="job_location_id"
                aria-label="Default select example"
                value={formData.job_location_id}
                onChange={onChange}
                invalid={errors.job_location_id ? true : false}
              >
                <LocationOptions />
              </Input>
            </div>
          </Col>

          <AddMoreDetails formData={formData} setFormData={setFormData} onChange={onChange} errors={errors} />

          <Col lg={12}>
            <div className="mb-4">
              <Label htmlFor="compDetails" className="form-label">
                Company Details *
              </Label>
              <Input
                type="textarea"
                className="form-control"
                autoComplete="off"
                id="compDetails"
                rows="3"
                placeholder="Enter Company Details"
                value={formData?.company_details ?? "dsd"}
                name="company_details"
                onChange={onChange}
                invalid={errors.company_details ? true : false}
              ></Input>
            </div>
          </Col>

          <Col lg={12}>
            <div className="mb-4">
              <label htmlFor="compAddress" className="form-label">
                Company Full Address *
              </label>
              <Input
                type="textarea"
                className="form-control"
                autoComplete="off"
                id="compAddress"
                rows="3"
                placeholder="Enter Company Address"
                value={formData?.company_address}
                onChange={onChange}
                name="company_address"
                invalid={errors.company_address ? true : false}
                multiple
              ></Input>
            </div>
          </Col>


          <Col lg={12}>
            <div className="d-flex flex-wrap align-items-start gap-1 justify-content-end">
              <Link to="/employer/company/manage" className="btn btn-success">
                Back
              </Link>
              {loading ? (
                <button type="submit" className="btn btn-primary disabled">
                  {formType === "update" ? "Update Now" : "Post Now"}{" "}
                  <Spinner size="sm" />
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={onFormSubmit}
                  className="btn btn-primary"
                >
                  {formType === "update" ? "Update Now" : "Post Now"}{" "}
                  <i className="mdi mdi-send"></i>
                </button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default ManageCompanyForm;
