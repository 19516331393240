import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getEmployerCompanies } from "../../../../../actions/employer";
import { addCompany } from "../../../../../api/companies";
import { apiErrorHandler } from "../../../../../helpers/common";
import { CustomSnackbar } from "../../../../../helpers/CustomSnackbar";
import ManageCompanyForm from "../ManageCompanyForm";

const AddFormSec = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const customer = useSelector((state) => state.customer.profile);
  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    setLoading(true);

    addCompany(formData)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch(getEmployerCompanies(customer?.id));
          CustomSnackbar.success("Company Added Successfully");
          navigate("/employer/company/manage");
        }
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="bg-soft-primary p-3">
              <h5 className="mb-0 fs-17">Add A New Company!</h5>
            </div>
          </Col>
        </Row>
        <ManageCompanyForm loading={loading} onSubmit={onSubmit} />
      </Container>
    </React.Fragment>
  );
};

export default AddFormSec;
