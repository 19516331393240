import React from "react";
import { Link, useLocation } from "react-router-dom";
import { DropdownMenu } from "reactstrap";

const EmployeeUserDropdown = ({ onLogout, toggleUserDropdown }) => {
  const { pathname } = useLocation();

  return (
    <DropdownMenu
      className="dropdown-menu-end cust-dropdown"
      aria-labelledby="userdropdown"
      end
    >
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/employer/dashboard" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/employer/dashboard"
        >
          DashBoard
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/employer/company/manage" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/employer/company/manage"
        >
          Manage Companies
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/employer/job/manage" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/employer/job/manage"
        >
          Manage Jobs
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/employer/job/application/manage" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/employer/job/application/manage"
        >
          Manage Job Applications
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/employer/bookmark-candidates" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/employer/bookmark-candidates"
        >
          Bookmarked Candidates
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/candidate/list" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/candidate/list"
        >
          Candidate List
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/employer/profile" ? "active" : ""
          }`}
          onClick={toggleUserDropdown}
          to="/employer/profile"
        >
          My Profile
        </Link>
      </li>
      <li>
        <Link className={`dropdown-item`} to="/" onClick={onLogout}>
          Logout
        </Link>
      </li>
    </DropdownMenu>
  );
};

export default EmployeeUserDropdown;
