import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, Row } from "reactstrap";
import JobCategoryOptions from "../../../helpers/JobCategoryOptions";
import LocationOptions from "../../../helpers/LocationOptions";

const JobSearchOptions = () => {
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let query = searchParams.get("q");
    let location = searchParams.get("location");
    let category = searchParams.get("category");

    if (query) {
      setQuery(query);
    }

    if (location) {
      setLocation(location);
    }

    if (category) {
      setCategory(category);
    }
  }, [searchParams]);

  const onSubmit = (e) => {
    e.preventDefault();

    let params = [];

    let job_type = searchParams.get("job_type");
    let keyword = searchParams.get("keyword");

    if (query) {
      params.push(`q=${query}`);
    }

    if (location) {
      params.push(`location=${location}`);
    }

    if (category) {
      params.push(`category=${category}`);
    }

    if (job_type) {
      params.push(`job_type=${job_type}`);
    }

    if (keyword) {
      params.push(`keyword=${keyword}`);
    }

    const newParams = `/jobs?${params?.join("&")}`;

    navigate(newParams);
  };

  return (
    <React.Fragment>
      <div className='job-list-header'>
        <Form onSubmit={onSubmit}>
          <Row className='g-2'>
            <Col lg={3} md={6}>
              <div className='filler-job-form search-input-box'>
                <i className='uil uil-briefcase-alt'></i>
                <Input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type='search'
                  className='form-control filter-job-input-box-option'
                  id='exampleFormControlInput1'
                  placeholder='Enter Job Title'
                />

                {/* <JobSearch query={query} setQuery={setQuery} /> */}
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className='filler-job-form search-input-box'>
                <i className='uil uil-location-point'></i>
                <select
                  className='form-select form-select-option'
                  data-trigger
                  name='choices-single-location'
                  id='choices-single-location'
                  aria-label='Default select example'
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <LocationOptions />
                </select>
                {/* <LocationSearch setLocation={setLocation} location={location} /> */}
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className='filler-job-form search-input-box'>
                <i className='uil uil-clipboard-notes'></i>
                {/* <CategorySearch setCategory={setCategory} category={category} /> */}

                <select
                  className='form-select form-select-option px-5'
                  data-trigger
                  name='choices-single-categories'
                  id='choices-single-categories'
                  aria-label='Default select example'
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <JobCategoryOptions />
                </select>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <button
                className='btn btn-primary submit-btn w-100 '
                type='submit'
              >
                <i className='uil uil-filter'></i> Fliter
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default JobSearchOptions;
