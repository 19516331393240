import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse } from "reactstrap";
import { getSettingByKey, toArray } from "../../../../helpers/common";

const JobKeywords = ({ onFilterChange, handleClearFilter, searchParams }) => {
  const [toggleFifth, setToggleFifth] = useState(true);
  const [selected, setSelected] = useState("");
  let keyword = searchParams.get("keyword");
  
  useEffect(() => {
    
    if (keyword) {
      setSelected(keyword);
    }
  }, [keyword]);

  const settings = useSelector((state) => state.settings.data);
  const trendingKeywordsString = getSettingByKey(
    settings,
    "trending_keywords",
    {}
  );
  const trendingKeywords = toArray(trendingKeywordsString?.value, ",");

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== selected) {
      onFilterChange("keyword", value);
      setSelected(value);
    } else {
      setSelected("");
      handleClearFilter("keyword");
    }
  };

  return (
    <div className="accordion-item mt-3">
      <h2 className="accordion-header" id="tagCloud">
        <Button
          className="accordion-button"
          onClick={(e) => {
            e.preventDefault();
            setToggleFifth(!toggleFifth);
          }}
          role="button"
          id="collapseExample"
        >
          Job Keywords
        </Button>
      </h2>
      <Collapse isOpen={toggleFifth}>
        <div className="accordion-body">
          <div className="side-title d-flex flex-wrap align-items-start gap-1">
            {trendingKeywords?.map((keyword, index) => (
              <span
                key={"keywords-" + index}
                className={`badge tag-cloud fs-13 mt-2 cursor-pointer ${
                  keyword?.toLowerCase() === selected?.toLowerCase()
                    ? "active"
                    : ""
                }`}
                onClick={() => handleToggle(keyword)}
              >
                {keyword}
              </span>
            ))}

            {/* <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("design")}
            >
              design
            </span>
            <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("marketing")}
            >
              marketing
            </span>
            <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("business")}
            >
              business
            </span>
            <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("developer")}
            >
              developer
            </span> */}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default JobKeywords;
