import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Icon } from '@iconify/react';
import { useDispatch } from "react-redux";
import { logout } from "../../../../actions/customer";

const DashboardContent = () => {
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <div className='dashboard'>
      <Row>
        <Col sm={4}>
          <Link to="/employer/company/manage" className="my-card">
            <Icon icon={"bi:building"} color="#766df4" style={{ marginRight: 10 }} fontSize={22} />
            Manage companies
          </Link>
        </Col>

        <Col sm={4}>
          <Link to="/employer/job/manage" className="my-card">
            <Icon icon={"uim:bag"} color="#766df4" style={{ marginRight: 10 }} fontSize={22} />
            Manage Jobs
          </Link>
        </Col>

        <Col sm={4}>
          <Link to="/employer/job/application/manage" className="my-card">
            <Icon icon={"akar-icons:paper"} color="#766df4" style={{ marginRight: 10 }} fontSize={22} />
            Manage Job Applications
          </Link>
        </Col>


        <Col sm={4}>
          <Link to="/employer/bookmark-candidates" className="my-card">
            <Icon icon={"bi:bookmark-check"} color="#766df4" style={{ marginRight: 10 }} fontSize={22} />

            Bookmarked Candidates
          </Link>
        </Col>

        <Col sm={4}>
          <Link to="/employer/profile" className="my-card">
            <Icon icon={"iconoir:profile-circled"} color="#766df4" style={{ marginRight: 10 }} fontSize={22} />

            My Profile
          </Link>
        </Col>

        <Col sm={4}>
          <Link to="/"  onClick={onLogout} className="my-card">
            <Icon icon={"akar-icons:sign-out"} color="#766df4" style={{ marginRight: 10 }} fontSize={22} />
            Log Out
          </Link>
        </Col>

      </Row>
    </div>
  )
}

export default DashboardContent