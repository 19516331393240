import React from "react";
import { useSelector } from "react-redux";

const JobCategoryOptions = () => {
  const jobCategories = useSelector((state) => state.categories.data);
  const filteredCategories = jobCategories.filter(
    (cat) => cat?.name !== "Main"
  );

  const sortedCategory =
    filteredCategories?.sort((a, b) => a.name.localeCompare(b.name)) || [];

  return (
    <React.Fragment>
      <option value="">Select Industry</option>
      {sortedCategory?.map((cat, index) => {
        return (
          <option key={index} value={cat.id}>
            {cat.name}
          </option>
        );
      })}
    </React.Fragment>
  );
};

export default JobCategoryOptions;
