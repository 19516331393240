import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Selected = ({ itemData, buttonUrl, buttonText }) => {
  return (
    <React.Fragment>
      <Row className="align-items-center mb-">
        <Col lg={8} md={6}>
          <div className="mb-3 mb-lg-0">
            <h6 className="fs-16 mb-0">
              {" "}
              Showing {itemData?.length}{" "}
              {itemData?.length > 1 ? "results" : "result"}{" "}
            </h6>
          </div>
        </Col>

        <Col lg={4} md={6}>
          <Link to={buttonUrl} className="button-card" >
            <Icon
              icon={"akar-icons:plus"}
              color="#766df4"
              style={{ marginRight: 10 }}
              fontSize={16}
            />
            {buttonText || 'Add'}
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Selected;
