import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Collapse } from "reactstrap";

const WorkExperience = ({ onFilterChange, handleClearFilter, searchParams }) => {
  const [toggleSecond, setToggleSecond] = useState(true);

  let work_experience = searchParams.get("work_experience");

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("work_experience", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("work_experience");
    }
  };

  useEffect(() => {
    if (work_experience) {
      setChecked([work_experience]);
    }
  }, [work_experience]);

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='experienceOne'>
        <Button
          className='accordion-button'
          onClick={(e) => {
            e.preventDefault();
            setToggleSecond(!toggleSecond);
          }}
          role='button'
          id='collapseExample'
        >
          Work experience
        </Button>
      </h2>
      <Collapse isOpen={toggleSecond}>
        <div className='accordion-body'>
          <div className='side-title'>
            <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexCheckChecked1'
                checked={checked.length === 0}
                onChange={() => {
                  setChecked([]);
                  handleClearFilter("work_experience");
                }}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor='flexCheckChecked1'
              >
                All
              </label>
            </div>
            <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexCheckChecked2'
                checked={checked.indexOf("0-3") === -1 ? false : true}
                onChange={(e) => handleToggle("0-3")}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor='flexCheckChecked2'
              >
                0-3 years
              </label>
            </div>
            <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexCheckChecked3'
                checked={checked.indexOf("3-6") === -1 ? false : true}
                onChange={(e) => handleToggle("3-6")}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor='flexCheckChecked3'
              >
                3-6 years
              </label>
            </div>
            <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexCheckChecked4'
                checked={checked.indexOf("6-100") === -1 ? false : true}
                onChange={(e) => handleToggle("6-100")}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor='flexCheckChecked4'
              >
                More than 6 years
              </label>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default WorkExperience;
