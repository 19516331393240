import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import moment from "moment";
import { useSelector } from "react-redux";
import {
  authKey_local_storage_key,
  convertToSlug,
  getCompanyLogo,
  getJobExperiences,
  getSalaryAmount,
  isValidURL,
} from "../../../helpers/common";
import AddToBookmarkJob from "../../../helpers/Common/AddToBookmarkJob";
import ApplyJobModal from "../../../helpers/Common/ApplyJobModal";

const RightSideContent = ({ details }) => {
  //Apply for Job Model
  const [applyJobModal, setApplyJobModal] = useState(false);
  const [currJob, setCurrJob] = useState({});

  const authToken = localStorage.getItem(authKey_local_storage_key);

  const openApplyJobModal = (jobDeatails) => {
    setApplyJobModal(!applyJobModal);
    setCurrJob(jobDeatails);
  };
  const customer = useSelector((state) => state.customer.profile);

  const companyDetails = details.company;

  const companyDetailPageUrl = `/company/details/${
    companyDetails.id
  }/${convertToSlug(companyDetails?.name || "")}`;

  const candidateReducer = useSelector((state) => state.candidate);
  const appliedJobs = candidateReducer.jobApplications;

  const isAppliedJob = appliedJobs?.find(
    (item) => item?.job?.id === details?.id
  );

  return (
    <React.Fragment>
      <div className='side-bar ms-lg-4'>
        <Card className='job-overview'>
          <CardBody className='p-4'>
            <h6 className='fs-17'>Job Overview</h6>
            <ul className='list-unstyled mt-4 mb-0'>
              <li>
                <div className='d-flex mt-4'>
                  <i className='uil uil-user icon bg-soft-primary'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Job Title</h6>
                    <p className='text-muted mb-0'>{details.job_title}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex mt-4'>
                  <i className='uil uil-star-half-alt icon bg-soft-primary'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Experience</h6>
                    <p className='text-muted mb-0'>
                      {getJobExperiences(details)}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex mt-4'>
                  <i className='uil uil-location-point icon bg-soft-primary'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Location</h6>
                    <p className='text-muted mb-0'>
                      {details?.location?.name || "India"}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex mt-4'>
                  <i className='uil uil-usd-circle icon bg-soft-primary'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Offered Salary</h6>
                    <p className='text-muted mb-0'>
                      {getSalaryAmount(details, "month", false)}
                    </p>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-graduation-cap icon bg-soft-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Qualification</h6>
                    <p className="text-muted mb-0">Bachelor Degree</p>
                  </div>
                </div>
              </li> */}
              {/* <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-building icon bg-soft-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Industry</h6>
                    <p className="text-muted mb-0">Private</p>
                  </div>
                </div>
              </li> */}
              <li>
                <div className='d-flex mt-4'>
                  <i className='uil uil-history icon bg-soft-primary'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Date Posted</h6>
                    <p className='text-muted mb-0'>
                      Posted {moment(details?.created_at).fromNow()}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            {customer?.customer_type !== 1 ? (
              <div className='mt-3'>
                {isAppliedJob ? (
                  <Link
                    to='#applyNow'
                    className='btn btn-success btn-hover w-100 mt-2 disabled'
                  >
                    Already Applied <i className='mdi mdi-check'></i>
                  </Link>
                ) : (
                  <Link
                    to={!authToken ? "/signin" : "#"}
                    onClick={() => openApplyJobModal(details)}
                    className='btn btn-primary btn-hover w-100 mt-2'
                  >
                    Apply Now <i className='uil uil-arrow-right'></i>
                  </Link>
                )}

                <AddToBookmarkJob
                  size='lg'
                  jobDetails={details}
                  companyDetails={companyDetails}
                />
              </div>
            ) : null}
          </CardBody>
        </Card>

        <Card className='company-profile mt-4'>
          <CardBody className='p-4'>
            <div className='text-center'>
              <img
                src={getCompanyLogo(companyDetails?.logo)}
                alt={companyDetails?.name}
                className='img-fluid rounded-3 comp-logo'
              />

              <div className='mt-4'>
                <h6 className='fs-17 mb-1'>{companyDetails?.name}</h6>
                <p className='text-muted'>
                  Since {companyDetails?.establishment_year}
                </p>
              </div>
            </div>
            <ul className='list-unstyled mt-4'>
              <li>
                <div className='d-flex'>
                  <i className='uil uil-phone-volume text-primary fs-4'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Phone</h6>
                    <a
                      className='primary-link text-muted fs-14 mb-0'
                      href={`tel:${companyDetails?.contact_number}`}
                    >
                      {companyDetails?.contact_number}
                    </a>
                  </div>
                </div>
              </li>
              <li className='mt-3'>
                <div className='d-flex'>
                  <i className='uil uil-envelope text-primary fs-4'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Email</h6>
                    <a
                      className='primary-link text-muted fs-14 mb-0'
                      href={`mailto:${companyDetails?.email_address}`}
                    >
                      {companyDetails?.email_address}
                    </a>
                  </div>
                </div>
              </li>
              {companyDetails?.website_url && (
                <li className='mt-3'>
                  <div className='d-flex'>
                    <i className='uil uil-globe text-primary fs-4'></i>
                    <div className='ms-3'>
                      <h6 className='fs-14 mb-2'>Website</h6>
                      <a
                        className='primary-link text-muted fs-14 mb-0'
                        href={`${companyDetails?.website_url}`}
                      >
                        {companyDetails?.website_url}
                      </a>
                    </div>
                  </div>
                </li>
              )}
              <li className='mt-3'>
                <div className='d-flex'>
                  <i className='uil uil-map-marker text-primary fs-4'></i>
                  <div className='ms-3'>
                    <h6 className='fs-14 mb-2'>Location</h6>
                    <p className='text-muted fs-14 mb-0'>
                      {companyDetails?.location_name || "India"}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className='mt-4'>
              <Link
                to={companyDetailPageUrl}
                className='btn btn-primary btn-hover w-100 rounded'
              >
                <i className='mdi mdi-eye'></i> View Profile
              </Link>
            </div>
          </CardBody>
        </Card>

        {companyDetails?.map_location &&
        isValidURL(companyDetails?.map_location) ? (
          <div className='mt-4'>
            <h6 className='fs-16 mb-3'>Job location</h6>
            {companyDetails?.map_location &&
            isValidURL(companyDetails?.map_location) ? (
              <iframe
                src={companyDetails?.map_location}
                title='title'
                style={{ width: `100%`, height: `250` }}
                allowFullScreen=''
                loading='lazy'
              ></iframe>
            ) : (
              <p>Job location not available right now.</p>
            )}
          </div>
        ) : null}

        <ApplyJobModal
          currJob={currJob}
          openApplyJobModal={openApplyJobModal}
          applyJobModal={applyJobModal}
        />
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
