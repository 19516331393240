import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Input, Modal, ModalBody, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { login } from "../../../actions/customer";
import { CustomSnackbar } from "../../../helpers/CustomSnackbar";
import PasswordInput from "../../../helpers/Common/PasswordInput";

const SignInForm = ({ formData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  //Apply for Job Model
  const [forgetPassModal, setForgetPassModal] = useState(false);
  const openForgetPassModal = () => setForgetPassModal(!forgetPassModal);

  useEffect(() => {
    const userName = localStorage.getItem("userName");

    if (userName) {
      setUsername(userName);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!username) {
      CustomSnackbar.warning("Please enter your username");
      return;
    }

    if (password.length < 8) {
      CustomSnackbar.warning("Password should be at least 8 characters");
      return;
    }

    localStorage.setItem("userName", username);
    localStorage.setItem("customer_type", formData.customer_type);

    setIsLoading(true);

    dispatch(
      login({ ...formData, username, password }, formData.customer_type)
    ).then((res) => {
      setIsLoading(false);

      if (res?.data?.customer?.customer_type === formData?.customer_type) {
        if (formData?.customer_type === 1) {
          navigate("/employer/company/manage");
        } else {
          navigate("/candidate/profile/update");
        }
      }
    });
  };

  return (
    <>
      <Form onSubmit={onSubmit} className="auth-form">
        <div className="mb-3">
          <label htmlFor="usernameInput" className="form-label">
            Username
          </label>
          <Input
            type="text"
            className="form-control"
            id="usernameInput"
            placeholder="Enter your username"
            required
            name="username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </div>
        <div className="mb-3">
          <PasswordInput
            label="Password"
            id="passwordInput"
            name="password"
            required={true}
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <div className="form-check">
            <Link
              onClick={openForgetPassModal}
              to="#"
              className="float-end text-white"
            >
              Forgot Password?
            </Link>
          </div>
        </div>
        <div className="text-center">
          {isLoading ? (
            <button
              className="btn btn-white btn-hover w-100 disabled"
              type="button"
            >
              Sign In <Spinner size="sm" />
            </button>
          ) : (
            <button className="btn btn-white btn-hover w-100" type="submit">
              Sign In
            </button>
          )}
        </div>
      </Form>

      <div
        className="modal fade"
        id="resetPass"
        tabIndex="-1"
        aria-labelledby="resetPass"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={forgetPassModal} toggle={openForgetPassModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Reset Password
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openForgetPassModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div>
                <p className="text-muted text-center">
                  {" "}
                  If you want to reset your password then Contact{" "}
                  <a href="mailto:info@jobseekerspage.com">
                    info@jobseekerspage.com
                  </a>
                </p>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
