import React from "react";
import { Col, Container, Row } from "reactstrap";
import Section from "./Section";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { getCustomerList } from "../../api/customer";
import NotFound from "../../helpers/Common/NotFound";
import SmallLoader from "../../helpers/Common/SmallLoader";
import CandidateRow from "../../helpers/Common/CandidateRow";
import { scrollWindow } from "../../helpers/common";
import CandidateFilters from "./CandidateFilters";

const CandidateList = () => {
  const [candidateList, setCandidateList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCustomerList(2)
      .then((res) => {
        setCandidateList(res.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => setLoading(false));

    scrollWindow();
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Candidate List | Jobseekers Page </title>
      </MetaTags>
      <Section />

      {loading ? (
        <SmallLoader />
      ) : candidateList?.length > 0 ? (
        <section className="section">
          <Container>
            <CandidateFilters />
            <Row>
              <Col lg={12}>
                {candidateList.map((details, index) => (
                  <CandidateRow
                    key={details?.id + "-" + index}
                    details={details}
                  />
                ))}
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <NotFound
          icon="ic:round-people-alt"
          message="Candidate list not found."
        />
      )}
    </React.Fragment>
  );
};

export default CandidateList;
