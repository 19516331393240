import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Progress, Row } from "reactstrap";
import styles from "./styles.module.css";

const ProfileCompletion = () => {
  const { profile } = useSelector((state) => state.customer);
  const navigate = useNavigate();

  const additional_fields = profile?.additional_fields
    ? JSON.parse(profile?.additional_fields)
    : {};

  const profileCompPercentage =
    additional_fields?.profile_completion_percentage || 0;

  const isProfileCompleted = parseInt(profileCompPercentage) >= 100;
  const profileForm = document.getElementById("profile-form");

  const scrollToForm = useCallback(() => {
    if (profileForm) {
      profileForm.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [profileForm]);

  useEffect(() => {
    scrollToForm();
  }, [profileForm, scrollToForm]);

  const onClick = () => {
    navigate("/candidate/profile/update");
    scrollToForm();
  };

  return (
    <>
      <div className={styles.trackerContainer}>
        <Row>
          <Col lg={4}>
            <div className={styles.leftBox}>
              <h1>{parseInt(profileCompPercentage)}%</h1>
              <h4>of your profile is completed</h4>
            </div>
          </Col>
          <Col lg={8}>
            <div className={styles.progressbarSec}>
              <div className={styles.progressDiv}>
                <Progress
                  value={profileCompPercentage}
                  color={isProfileCompleted ? "success" : "primary"}
                  className={styles.progress}
                />
              </div>
            </div>

            <h4 className={styles.smallHeading}>
              {isProfileCompleted
                ? "Profile Completed"
                : "Complete your profile to apply for better jobs!"}
            </h4>

            <p className={styles.hintText}>
              {!isProfileCompleted
                ? " Complete your profile to get started. the more you complete the profile the chance will increase for getting a better job."
                : "Congratulations on completing your profile! Your thorough and comprehensive profile increases your chances of finding the right job for you. "}
            </p>

            {!isProfileCompleted && (
              <button
                type="submit"
                className={`btn btn-primary ${styles.button}`}
                onClick={onClick}
              >
                Complete profile
              </button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfileCompletion;
