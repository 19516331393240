import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col } from "reactstrap";
import {
  getFilterPath,
  getUrlParams,
  removeUrlParam,
} from "../../../../helpers/common";
import DatePosted from "./DatePosted";
import EmploymentType from "./EmploymentType";
import JobKeywords from "./JobKeywords";
import WorkExperience from "./WorkExperience";

const Sidebar = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onFilterChange = (filterName, filterValue) => {
    let params = getUrlParams(filterName, filterValue);
    let path = getFilterPath(params);
    navigate(path);
  };

  const handleClearFilter = (urlPrams) => {
    const params = removeUrlParam(urlPrams);
    let path = getFilterPath(params);
    navigate(path);
  };

  return (
    <React.Fragment>
      <Col lg={3}>
        <div className='side-bar mt-5 mt-lg-0'>
          <div className='accordion' id='accordionExample'>
            <WorkExperience
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />

            <EmploymentType
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />

            <DatePosted
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />

            <JobKeywords
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Sidebar;
