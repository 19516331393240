import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyEmailOtp } from "../../../api/customer";
import LoadingScreen from "../../../helpers/Common/LoadingScreen";
import "./styles.css";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const verificationOtp = searchParams.get("email_verification_otp");
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [verifyStatus, setVerfiyStatus] = useState({
    error: null,
    success: true,
  });

  const handleVerifyEmail = useCallback(() => {
    const payload = {
      email,
      email_verification_otp: verificationOtp,
    };

    setLoading(true);

    verifyEmailOtp(payload)
      .then((res) => {
        setVerfiyStatus({ success: res, error: null });
      })
      .catch((err) => {
        setVerfiyStatus({ error: err, success: null });
      })
      .finally(() => setLoading(false));
  }, [email, verificationOtp]);


  useEffect(() => {
    if (email && verificationOtp) {
      handleVerifyEmail();
    }
  }, [email, handleVerifyEmail, verificationOtp]);

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth verify-email-sec">
              {loading && <LoadingScreen />}
              <div className="verify-email-box">
                {verifyStatus.success ? (
                  <>
                    <h5 className="title">Email verified</h5>

                    <p>Success! Welcome to Jobseekers Page!</p>

                    <button
                      className="btn btn-primary btn-hover my-button"
                      type="button"
                      onClick={() => navigate('/signin')}
                    >
                      Continue to login
                    </button>
                  </>
                ) : (
                  <>
                    <h5 className="title">Your email has not been verified!</h5>

                    <p>You’re almost there! We’ve sent an email</p>

                    <p>
                      Just click the link in that email to complete your signup.
                      If you don’t see it, you may need to check your spam
                      folder.
                    </p>

                    <p>Still can’t find the email?</p>

                    <button
                      className="btn btn-danger btn-hover my-button"
                      type="button"
                      onClick={() => navigate('/signin')}
                    >
                      Back to login
                    </button>

                    <button
                      className="btn btn-primary btn-hover my-button"
                      type="button"
                    >
                      Resend Email
                    </button>
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifyEmail;
