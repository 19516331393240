import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { scrollWindow } from "../../../../helpers/common";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import BookmarkJobItem from "./BookmarkJobItem";
import NoBookmarkJob from "./NoBookmarkJob";
import Section from "./Section";

const ManageBookmarkJobs = () => {

  const bookmarkJobs = useSelector((state) => state.bookmarkJob.data);
  const loading = useSelector((state) => state.bookmarkJob.loading);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    scrollWindow();
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Manage Bookmarked Jobs | Jobseekers Page </title>
      </MetaTags>
      <Section />
      {(isLoading || loading) && <SmallLoader />}

      <section
        className={`section ${isLoading || loading ? "d-none" : "d-block"}`}
      >
        <Container>
          {bookmarkJobs && bookmarkJobs?.length > 0 ? (
            bookmarkJobs?.map((item) => {
              return (
                <BookmarkJobItem
                  key={item.id}
                  bookmarkItem={item}
                />
              );
            })
          ) : (
            <NoBookmarkJob />
          )}

        </Container>
      </section>
    </React.Fragment>
  );
};

export default ManageBookmarkJobs;
