import React from "react";
import { useSelector } from "react-redux";
import { getSettingByKey, toArray } from "./common";

const DesignationOptions = () => {
  const settings = useSelector((state) => state.settings.data);

  const jobDesignationList = toArray(
    getSettingByKey(settings, "job_designations")?.value,
    "\n"
  );

  return (
    <React.Fragment>
      <option value=''>Select designation</option>
      {jobDesignationList?.map((name, index) => {
        return (
          <option key={index} value={name}>
            {name}
          </option>
        );
      })}
    </React.Fragment>
  );
};

export default DesignationOptions;
