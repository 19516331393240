import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  convertToSlug,
  getCompanyLogo,
  getJobExperiences,
  getKeywords,
  getSalaryAmount,
} from "../../../../helpers/common";
import { useSelector } from "react-redux";
import AddToBookmarkJob from "../../../../helpers/Common/AddToBookmarkJob";
import ApplyJobModal from "../../../../helpers/Common/ApplyJobModal";

const BookmarkJobItem = ({ bookmarkItem }) => {
  const [applyJobModal, setApplyJobModal] = useState(false);
  const [currJob, setCurrJob] = useState({});
  const openApplyJobModal = (jobDetails) => {
    setApplyJobModal(!applyJobModal);
    setCurrJob(jobDetails);
  };

  // Getting the company details
  const companyDetails = bookmarkItem?.company;

  // Getting the job details
  const jobDetails = bookmarkItem?.job;

  // Job detail page URL
  const jobDetailsUrl = `/job/details/${jobDetails?.id}/${convertToSlug(
    jobDetails?.job_title || ""
  )}`;

  const candidateReducer = useSelector((state) => state.candidate);
  const appliedJobs = candidateReducer.jobApplications;

  const isAppliedJob = appliedJobs?.find(
    (item) => item?.job?.id === jobDetails?.id
  );

  return (
    <>
      <div className={"job-box card mt-4"}>
        <div className='p-4'>
          <Row>
            <Col lg={1}>
              <Link to={jobDetailsUrl}>
                <img
                  src={getCompanyLogo(companyDetails?.logo)}
                  alt={companyDetails.name}
                  className='img-fluid rounded-3 comp-logo'
                />
              </Link>
            </Col>
            <Col lg={10}>
              <div className='mt-3 mt-lg-0'>
                <h5 className='fs-17 mb-1'>
                  <Link to={jobDetailsUrl} className='text-dark'>
                    {jobDetails?.job_title}
                  </Link>{" "}
                  <small className='text-muted fw-normal'>
                    ({getJobExperiences(jobDetails)} Exp.)
                  </small>
                </h5>
                <ul className='list-inline mb-0'>
                  <li className='list-inline-item'>
                    <p className='text-muted fs-14 mb-0'>
                      {companyDetails?.name}
                    </p>
                  </li>
                  <li className='list-inline-item'>
                    <p className='text-muted fs-14 mb-0'>
                      <i className='mdi mdi-map-marker'></i>
                      {jobDetails?.location?.name || "India"}
                    </p>
                  </li>
                  <li className='list-inline-item'>
                    <p className='text-muted fs-14 mb-0'>
                      {getSalaryAmount(jobDetails)}
                    </p>
                  </li>
                </ul>
                <div className='mt-2'>
                  <span
                    className={
                      jobDetails?.job_type?.toLowerCase() === "full time"
                        ? "badge bg-soft-success fs-13 mt-1 mx-1"
                        : jobDetails?.job_type?.toLowerCase() === "part time"
                        ? "badge bg-soft-danger fs-13 mt-1 mx-1"
                        : jobDetails?.job_type?.toLowerCase() === "freelancer"
                        ? "badge bg-soft-purple fs-13 mt-1 mx-1"
                        : "badge bg-soft-secondary fs-13 mt-1 mx-1"
                    }
                  >
                    {jobDetails?.job_type}
                  </span>

                  <span
                    className={
                      jobDetails?.job_position?.toLowerCase() === "fresher"
                        ? "badge bg-soft-blue fs-13 mt-1 mx-1"
                        : jobDetails?.job_position?.toLowerCase() === "junior"
                        ? "badge bg-soft-pink fs-13 mt-1 mx-1"
                        : jobDetails?.job_position?.toLowerCase() === "senior"
                        ? "badge bg-soft-info fs-13 mt-1 mx-1"
                        : "badge bg-soft-secondary fs-13 mt-1 mx-1"
                    }
                  >
                    {jobDetails?.job_position}
                  </span>

                  {jobDetails?.job_priority?.toLowerCase() === "high" && (
                    <span className={`badge bg-soft-warning fs-13 mt-1`}>
                      Urgent
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {/* Add to Bookmark Button */}
          <AddToBookmarkJob
            size='sm'
            companyDetails={companyDetails}
            jobDetails={jobDetails}
          />
        </div>
        <div className='p-3 bg-light'>
          <div className='row justify-content-between'>
            <div className='col-md-8'>
              <div>
                <ul className='list-inline mb-0'>
                  <li className='list-inline-item'>
                    <i className='uil uil-tag'></i> Keywords :
                  </li>
                  {getKeywords(jobDetails?.search_keywords)}
                </ul>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='text-md-end'>
                {isAppliedJob ? (
                  <span style={{ color: "green" }}>
                    Already Applied <i className='mdi mdi-check'></i>
                  </span>
                ) : (
                  <Link
                    to='#'
                    onClick={() => openApplyJobModal(jobDetails)}
                    className='primary-link'
                  >
                    Apply Now <i className='mdi mdi-chevron-double-right'></i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ApplyJobModal
        currJob={currJob}
        openApplyJobModal={openApplyJobModal}
        applyJobModal={applyJobModal}
        jobDetails={jobDetails}
      />
    </>
  );
};

export default BookmarkJobItem;
