import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Col, Row, CardBody } from 'reactstrap';
import MetaTags from "react-meta-tags";
import signUpImage from "../../../assets/images/auth/sign-up.png";
import SignUpForm from './SignUpForm';
import WebsiteLogo from '../../../helpers/WebsiteLogo';
const SignUp = () => {

  const initial_state = {
    name: '',
    username: '',
    email: '',
    mobile_number: '',
    password: '',
    confirm_password: '',
    customer_type: 2
  }

  const [tab, setTab] = React.useState(2);
  const [formData, setFormData] = useState(initial_state);

  const changeTab = (tab) => {
    setTab(tab);
    setFormData({ ...formData, customer_type: tab });
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <MetaTags>
              <title>Sign Up | Jobseekers Page </title>
            </MetaTags>
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <Card className="auth-box">
                      <Row className="align-items-center">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <WebsiteLogo height="30" />
                            </Link>
                            <div className="mt-5">
                              <img src={signUpImage} alt="" className="img-fluid" />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6} >

                          <CardBody className="auth-content p-5 text-white">
                            <div className="w-100">
                              <div className="text-center">
                                <h5>Let's Get Started</h5>
                                <p className="text-white-70">Sign Up and get access to all the features of Jobseekers Page</p>
                              </div>

                              {/* tabs  */}

                              <div className="tabs-sec innerpage-tabs-sec">

                                <div
                                  className={`tab left-tab  ${tab === 2 && 'active'}`}
                                  onClick={() => changeTab(2)} >
                                  <h6>Candidate</h6>
                                  <h4>Sign Up as a Candidate</h4>
                                </div>

                                <div
                                  className={`tab right-tab ${tab === 1 && 'active'}`}
                                  onClick={() => changeTab(1)} >
                                  <h6>Employer</h6>
                                  <h4>Sign Up as a Employer</h4>
                                </div>
                              </div>

                              <SignUpForm
                                formData={formData}
                                setFormData={setFormData}
                                initial_state={initial_state} />

                              <div className="mt-3 text-center">
                                <p className="mb-0">Already a member ? <Link to="/signin" className="fw-medium text-white text-decoration-underline"> Sign In </Link></p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignUp
