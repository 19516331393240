import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { scrollWindow } from "../../../../helpers/common";
import CandidateRow from "../../../../helpers/Common/CandidateRow";
import NotFound from "../../../../helpers/Common/NotFound";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import Section from "./Section";

const BookMarkCandidates = () => {
  const bookmarkCandidates = useSelector((state) => state.bookmarkCandidates);
  const candidatesList = bookmarkCandidates.data;
  const loading = bookmarkCandidates.loading;

  

  useEffect(() => {
    scrollWindow();
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Bookmarked Candidates | Jobseekers Page </title>
      </MetaTags>
      <Section />

      {loading ? (
        <SmallLoader />
      ) : candidatesList?.length > 0 ? (
        <section className="section">
          <Container>
            {candidatesList?.map((candi) => {
              return <CandidateRow details={candi} key={candi?.id} />;
            })}
          </Container>
        </section>
      ) : (
        <NotFound
          icon="ic:round-people-alt"
          message="You don't have bookmark candidates list."
        />
      )}
    </React.Fragment>
  );
};

export default BookMarkCandidates;
