import { useEffect } from "react";
import { useState } from "react";
import { Col, Row, Input, Form, Label, Button, Spinner } from "reactstrap";
import ExperienceModal from "./ExperienceModal";
import QualificationModal from "./QualificationModal";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../actions/customer";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";
import {
  authKey_local_storage_key,
  sumObjectValues,
  userInfo_local_storage_key,
} from "../../../../helpers/common";
import LocationOptions from "../../../../helpers/LocationOptions";
import QualificationJourney from "./QualificationJourney";
import ExperienceJourney from "./ExperienceJourney";
import DocAttachments from "./DocAttachments";
import ImageUploader from "../../../../helpers/Common/ImageUploader";
import AddMoreDetails from "./AddMoreDetails";
import DesignationOptions from "../../../../helpers/DesignationOptions";

const UpdateProfile = () => {
  const [loading, setLoading] = useState(false);
  const [profileCompPercentage, setProfileCompPercentage] = useState(0);

  // Qualification states
  const [openQualificationModal, setOpenQualificationModal] = useState(false);
  const [qualificationModalType, setQualificationModalType] = useState("add");
  const [qualificationData, setQualificationData] = useState(null);

  // Experience states
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [experienceModalType, setExperienceModalType] = useState("add");
  const [experienceData, setExperienceData] = useState(null);

  // Qualification modal handle
  const handleOpenQualificationModal = (type, data = null) => {
    setOpenQualificationModal(true);
    setQualificationModalType(type);
    setQualificationData(data);
  };

  // Experience modal handle
  const handleOpenExperienceModal = (type, data = null) => {
    setOpenExperienceModal(true);
    setExperienceModalType(type);
    setExperienceData(data);
  };

  const { qualifications, experiences } = useSelector(
    (state) => state.customer
  );

  const token = localStorage.getItem(authKey_local_storage_key);

  const { profile } = useSelector((state) => state.customer);

  const additional_fields = profile?.additional_fields
    ? JSON.parse(profile?.additional_fields)
    : {};

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});


  useEffect(() => {
    const additional_fields = profile?.additional_fields
      ? JSON.parse(profile?.additional_fields)
      : {};

    const initial_state = {
      name: profile?.name || "",
      username: profile?.username || "",
      email: profile?.email || "",
      mobile_number: profile?.mobile_number || "",
      details: additional_fields?.details || "",
      languages_known: additional_fields?.languages_known || "",
      location: additional_fields?.location || "",
      designation: additional_fields?.designation || "",
      current_salary_per_month:
        additional_fields?.current_salary_per_month || "",
      expected_salary_per_month:
        additional_fields?.expected_salary_per_month || "",
      skills: additional_fields?.skills || "",
      facebook_url: additional_fields?.facebook_url || "",
      twitter_url: additional_fields?.twitter_url || "",
      linkedin_url: additional_fields?.linkedin_url || "",
      whatsapp_number: additional_fields?.whatsapp_number || "",
      profile_picture: additional_fields?.profile_picture || "",
      resume: additional_fields?.resume || "",
      resume_size: additional_fields?.resume_size || "",
      cover_letter: additional_fields?.cover_letter || "",
      cover_letter_size: additional_fields?.cover_letter_size || "",
      hobbies: additional_fields?.hobbies || "",
      permanent_address: additional_fields?.permanent_address || "",
      present_address: additional_fields?.present_address || "",
      state: additional_fields?.state || "",
      same_as_permanent_address: additional_fields?.same_as_permanent_address || "",
    };

    setFormData(initial_state);
  }, [profile]);

  const onChangeValue = (e, value) => {
    setFormData({ ...formData, [value]: e.target.value });
  };

  // Updating profile details
  const updateProfileHandle = (newProfilePic) => {
    if (newProfilePic) {
      setFormData((formData) => ({
        ...formData,
        profile_picture: newProfilePic,
      }));
    }

    const payload = {
      name: formData?.name,
      email: formData?.email,
      mobile_number: formData?.mobile_number,
      AdditionalFields: {
        details: formData?.details,
        languages_known: formData?.languages_known,
        skills: formData?.skills,
        location: formData?.location,
        designation: formData?.designation,
        current_salary_per_month: formData?.current_salary_per_month,
        expected_salary_per_month: formData?.expected_salary_per_month,
        facebook_url: formData?.facebook_url,
        twitter_url: formData?.twitter_url,
        linkedin_url: formData?.linkedin_url,
        whatsapp_number: formData?.whatsapp_number,
        profile_picture: newProfilePic ?? formData?.profile_picture,
        resume: formData?.resume || "",
        cover_letter: formData?.cover_letter || "",
        resume_size: formData?.resume_size?.toString() || "",
        cover_letter_size: formData?.cover_letter_size?.toString() || "",
        profile_completion_percentage: profileCompPercentage?.toString(),
        hobbies: formData?.hobbies || "",
        permanent_address: formData?.permanent_address || "",
        present_address: formData?.present_address || "",
        state: formData?.state || "",
        same_as_permanent_address: formData?.same_as_permanent_address || "",
      },
    };

    dispatch(updateProfile({ ...payload, id: profile.id })).then((res) => {
      setLoading(false);

      if (res.status === 200 && res.data.id) {
        CustomSnackbar.success(
          `Profile ${newProfilePic ? "picture" : ""} updated successfully.`
        );
        const customer = res.data;

        localStorage.setItem(
          userInfo_local_storage_key,
          JSON.stringify({ customer, token })
        );
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.name) {
      CustomSnackbar.error("Please enter your name");
      return;
    }

    if (!formData.email) {
      CustomSnackbar.error("Please enter your email address");
      return;
    }

    if (!formData.mobile_number) {
      CustomSnackbar.error("Please enter your mobile number");
      return;
    }
    
    if (!formData.designation) {
      CustomSnackbar.error("Please enter your designation");
      return;
    }
    
    if (!formData.location) {
      CustomSnackbar.error("Please enter your location");
      return;
    }

    if (!formData.current_salary_per_month) {
      CustomSnackbar.error("Please enter your current salary.");
      return;
    }

    setLoading(true);

    updateProfileHandle();
  };

  useEffect(() => {
    var totalScore = 0;
    var inputs = document.querySelectorAll("input");
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i]?.getAttribute("data-score")) {
        totalScore += parseInt(inputs[i].getAttribute("data-score"));
      }
    }

    const additionalScore = {
      resumeScore: 3,
      coverLetterScore: 1,
      qualificationScore: 3,
      experienceScore: 2,
    };

    totalScore += sumObjectValues(additionalScore);

    var filledScore = 0;
    for (var e = 0; e < inputs.length; e++) {
      if (inputs[e].value) {
        if (inputs[e]?.getAttribute("data-score")) {
          // console.log("eeee::", inputs[e].getAttribute("data-score"));
          filledScore += parseInt(inputs[e].getAttribute("data-score"));
        }
      }
    }

    if (formData.resume) {
      filledScore += 3;
    }

    if (formData.cover_letter) {
      filledScore += 1;
    }

    if (qualifications?.length > 0) {
      filledScore += 3;
    }

    if (experiences?.length > 0) {
      filledScore += 2;
    }

    var percentage = (filledScore / totalScore) * 100;

    setProfileCompPercentage(percentage);
  }, [experiences, formData, qualifications, setProfileCompPercentage]);

  return (
    <div id="profile-form">
      <Form onSubmit={onSubmit}>
        <div>
          <ImageUploader
            uploadPath="/customers/attachment"
            assetType="customers"
            updateImageHandle={updateProfileHandle}
            imageName={additional_fields?.profile_picture}
          />

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name *
                </label>
                <Input
                  data-score={3}
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Enter your full name"
                  value={formData.name}
                  onChange={(e) => onChangeValue(e, "name")}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="userName" className="form-label">
                  User Name *
                </Label>
                <Input
                  data-score={3}
                  type="text"
                  className="form-control"
                  id="userName"
                  placeholder="your user name"
                  value={formData.username}
                  onChange={(e) => onChangeValue(e, "username")}
                  disabled
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  Email *
                </Label>
                <Input
                  data-score={3}
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={(e) => onChangeValue(e, "email")}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="mobile" className="form-label">
                  Mobile Number *
                </Label>
                <Input
                  data-score={3}
                  type="text"
                  className="form-control"
                  id="mobile"
                  placeholder="Enter your mobile number"
                  value={formData.mobile_number}
                  onChange={(e) => onChangeValue(e, "mobile_number")}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-2">
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="designation" className="form-label">
                  Designation *
                </Label>
                <select
                  className="form-select"
                  data-trigger
                  data-score={2}
                  name="choices-single-designation"
                  id="choices-single-designation"
                  aria-label="Default select example"
                  value={formData.designation}
                  onChange={(e) => onChangeValue(e, "designation")}
                >
                  <DesignationOptions />
                </select>
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <label htmlFor="choices-single-location" className="form-label">
                  Your Location *
                </label>
                <select
                  className="form-select"
                  data-trigger
                  data-score={2}
                  name="choices-single-location"
                  id="choices-single-location"
                  aria-label="Default select example"
                  value={formData.location}
                  onChange={(e) => onChangeValue(e, "location")}
                >
                  <LocationOptions />
                </select>
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label
                  htmlFor="current_salary_per_month"
                  className="form-label"
                >
                  Current Salary Per Month *
                </Label>
                <Input
                  data-score={3}
                  type="number"
                  className="form-control"
                  id="current_salary_per_month"
                  placeholder="Enter current salary per month"
                  value={formData.current_salary_per_month}
                  onChange={(e) => onChangeValue(e, "current_salary_per_month")}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label
                  htmlFor="expected_salary_per_month"
                  className="form-label"
                >
                  Expected Salary Per Month
                </Label>
                <Input
                  data-score={1}
                  type="number"
                  className="form-control"
                  id="expected_salary_per_month"
                  placeholder="Enter expected salary per month"
                  value={formData.expected_salary_per_month}
                  onChange={(e) =>
                    onChangeValue(e, "expected_salary_per_month")
                  }
                />
              </div>
            </Col>
          </Row>

          <AddMoreDetails formData={formData} setFormData={setFormData} onChangeValue={onChangeValue} />

        </div>

        <div className="mt-4">
          <div className="cust-divider"></div>

          <div className="d-flex justify-content-between mt-4">
            <h5 className="fs-17 fw-semibold mb-3">Qualifications</h5>
            <Button
              color="primary"
              onClick={() => handleOpenQualificationModal("add")}
              outline
              size="sm"
            >
              Add Qualification
            </Button>
          </div>

          <div className="mt-3">
            <QualificationJourney
              handleOpenQualificationModal={handleOpenQualificationModal}
              componentType="update"
            />
          </div>

          <div className="cust-divider"></div>
        </div>

        <div className="mt-5">
          <div className="d-flex justify-content-between">
            <h5 className="fs-17 fw-semibold mb-3">Experiences</h5>
            <Button
              color="primary"
              onClick={() => handleOpenExperienceModal("add")}
              outline
              size="sm"
            >
              Add Experience
            </Button>
          </div>

          <div className="mt-3">
            <ExperienceJourney
              handleOpenExperienceModal={handleOpenExperienceModal}
              componentType="update"
            />
          </div>

        </div>

        <DocAttachments setFormData={setFormData} />


        <div className="mt-5 text-end">
          {loading ? (
            <button type="button" className="btn btn-primary w-100 disabled">
              Update Profile <Spinner size="sm" />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary w-100">
              Update Profile
            </button>
          )}
        </div>
      </Form>

      {openQualificationModal && (
        <QualificationModal
          openQualificationModal={openQualificationModal}
          setOpenQualificationModal={setOpenQualificationModal}
          modalType={qualificationModalType}
          qualificationData={qualificationData}
        />
      )}

      {openExperienceModal && (
        <ExperienceModal
          openExperienceModal={openExperienceModal}
          setOpenExperienceModal={setOpenExperienceModal}
          modalType={experienceModalType}
          experienceData={experienceData}
        />
      )}
    </div>
  );
};

export default UpdateProfile;
