import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { getBookmarkCandidates } from "../../actions/bookmarkCandidates";
import {
  bookmarkCandidate,
  removeBookmarkCandidate,
} from "../../api/bookmarkCandidates";
import { apiErrorHandler } from "../common";
import { CustomSnackbar } from "../CustomSnackbar";

const AddToBookmarkCandidate = ({ candidateId }) => {
  const customer = useSelector((state) => state.customer.profile);
  const bookmarkCandidates = useSelector(
    (state) => state.bookmarkCandidates.data
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClick = (type, item) => {
    setLoading(true);

    if (type === 1) {
      // Add to bookmark candidate API
      bookmarkCandidate({ candidate_id: candidateId })
        .then((res) => {
          if (res?.data) {
            CustomSnackbar.success("Added to bookmark");
            dispatch(getBookmarkCandidates(customer?.id));
          }
        })
        .catch((err) => {
          apiErrorHandler(err);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    } else {
      removeBookmarkCandidate(item.id)
        .then((_) => {
          dispatch(getBookmarkCandidates(customer?.id));
          CustomSnackbar.success("Removed successfully.");
        })
        .catch((err) => {
          apiErrorHandler(err);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  };

  const findBookmarkedCandidate = bookmarkCandidates?.find(
    (item) => item?.candidate?.id === candidateId
  );

  return (
    <>
      {loading ? (
        <div className="favorite-icon">
          <span className="bg-danger d-flex align-items-center justify-content-center">
            <Spinner size="sm" color="white"></Spinner>
          </span>
        </div>
      ) : !findBookmarkedCandidate ? (
        <div className="favorite-icon" onClick={() => handleClick(1)}>
          <Link to="#">
            <i className="uil uil-heart-alt fs-18"></i>
          </Link>
        </div>
      ) : (
        <div
          className="favorite-icon"
          onClick={() => handleClick(0, findBookmarkedCandidate)}
        >
          <Link to="#" className="bg-danger">
            <i className="uil uil-heart-alt fs-18 text-white"></i>
          </Link>
        </div>
      )}
    </>
  );
};

export default AddToBookmarkCandidate;
