import React from "react";
import { useSelector } from "react-redux";

const LocationOptions = () => {
  const jobLocations = useSelector((state) => state.jobLocations.data) || [];

  return (
    <React.Fragment>
      <option value="">Select Location</option>
      {jobLocations?.map((item, index) => {
        return (
          <option key={index} value={item?.id}>
            {item?.name}
          </option>
        );
      })}
    </React.Fragment>
  );
};

export default LocationOptions;
