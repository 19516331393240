import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Collapse, Input } from "reactstrap";

const EmploymentType = ({ onFilterChange, handleClearFilter, searchParams }) => {
  const [toggleThird, setToggleThird] = useState(true);
  const [checked, setChecked] = useState([]);

  let job_type = searchParams.get("job_type");

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("job_type", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("job_type");
    }
  };

  useEffect(() => {
    if (job_type) {
      setChecked([job_type]);
    }
  }, [job_type]);


  return (
    <div className="accordion-item mt-3">
      <h2 className="accordion-header" id="jobType">
        <Button
          className="accordion-button"
          onClick={(e) => {
            e.preventDefault();
            setToggleThird(!toggleThird);
          }}
          role="button"
          id="collapseExample"
        >
          Type of employment
        </Button>
      </h2>
      <Collapse isOpen={toggleThird}>
        <div className="accordion-body">
          <div className="side-title">
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="flexCheckboxDefault"
                id="flexCheckboxDefault6"
                checked={checked.indexOf("Freelancer") === -1 ? false : true}
                onChange={(e) => handleToggle("Freelancer")}
              />
              <label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckboxDefault6"
              >
                Freelance
              </label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="flexCheckboxDefault"
                id="flexCheckboxDefault2"
                checked={checked.indexOf("Full Time") === -1 ? false : true}
                onChange={(e) => handleToggle("Full Time")}
              />
              <label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckboxDefault2"
              >
                Full Time
              </label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="flexCheckboxDefault"
                id="flexCheckboxDefault3"
                onChange={(e) => handleToggle("Internship")}
                checked={checked.indexOf("Internship") === -1 ? false : true}
              />
              <label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckboxDefault3"
              >
                Internship
              </label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="flexCheckboxDefault"
                id="flexCheckboxDefault4"
                onChange={(e) => handleToggle("Part Time")}
                checked={checked.indexOf("Part Time") === -1 ? false : true}
              />
              <label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckboxDefault4"
              >
                Part Time
              </label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="flexCheckboxDefault"
                id="flexCheckboxDefault5"
                onChange={(e) => handleToggle("Temporary")}
                checked={checked.indexOf("Temporary") === -1 ? false : true}
              />
              <label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckboxDefault5"
              >
                Temporary
              </label>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default EmploymentType;
