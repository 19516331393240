import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getContentsByCustomerId } from "../../api/contents";
import { getFirstChar } from "../../helpers/common";

const QualificationJourney = ({
  handleOpenQualificationModal,
  componentType = "overview",
}) => {
  const { candidateId } = useParams();
  const [qualifications, setQualifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getContentsByCustomerId(`candidate_qualifications`, candidateId)
      .then((res) => {
        setQualifications(res.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => setLoading(false));
  }, [candidateId]);

  return (
    <>
      {loading ? (
        <p style={{ margin: "25px 0" }}>Loading...</p>
      ) : qualifications && qualifications?.length > 0 ? (
        qualifications?.map((item) => {
          return (
            <div
              key={item?.id}
              className="candidate-education-content mt-4 d-flex"
            >
              <div className="circle flex-shrink-0 bg-soft-primary">
                {getFirstChar(item?.additional_fields?.title)}
              </div>
              <div className="ms-4">
                <div className="d-flex">
                  <h6 className="fs-16 mb-1 me-3">
                    {item?.additional_fields?.title}
                  </h6>

                  {componentType === "update" ? (
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        handleOpenQualificationModal("update", item)
                      }
                    >
                      <i className="uil uil-edit"></i>
                    </div>
                  ) : null}
                </div>
                <p className="mb-2 text-muted">
                  {item?.additional_fields?.university_college} - (
                  {item?.additional_fields?.start_year} -{" "}
                  {item?.additional_fields?.end_year})
                </p>
                <p className="text-muted">{item?.additional_fields?.details}</p>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <p className="mt-4 mb-5">
            No qualification information has been added.
          </p>
        </>
      )}
    </>
  );
};

export default QualificationJourney;
