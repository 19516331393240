import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  convertToSlug,
  getCompanyLogo,
  getSalaryAmount,
} from "../../../../helpers/common";

const JobItemRow = ({ jobDetails }) => {
  const jobDetailsUrl = `/job/details/${jobDetails?.id}/${convertToSlug(
    jobDetails.name || ""
  )}`;

  const companyDetails = jobDetails.company;

  return (
    <>
      <Card className='job-box card mt-4'>
        <CardBody className='p-4'>
          <Row>
            <Col lg={1}>
              <Link to={jobDetailsUrl}>
                <img
                  src={getCompanyLogo(companyDetails?.logo)}
                  alt={jobDetails?.job_title}
                  className='img-fluid rounded-3 job-logo'
                />
              </Link>
            </Col>

            <Col lg={8}>
              <div className='mt-3 mt-lg-0'>
                <h5 className='fs-17 mb-1'>
                  <Link to={jobDetailsUrl} className='text-dark'>
                    {jobDetails?.job_title}
                  </Link>
                </h5>
                <ul className='list-inline mb-0'>
                  <li className='list-inline-item'>
                    {companyDetails?.name && (
                      <p className='text-muted fs-14 mb-0'>
                        <i className='mdi mdi-office-building'></i>{" "}
                        {companyDetails?.name}
                      </p>
                    )}
                  </li>
                  <li className='list-inline-item'>
                    <p className='text-muted fs-14 mb-0'>
                      <i className='mdi mdi-map-marker'></i>{" "}
                      {jobDetails?.location?.name || "India"}
                    </p>
                  </li>
                  <li className='list-inline-item'>
                    <p className='text-muted fs-14 mb-0'>
                      {getSalaryAmount(jobDetails)}
                    </p>
                  </li>
                </ul>
                <div className='mt-2'>
                  <span
                    className={
                      jobDetails?.job_type?.toLowerCase() === "full time"
                        ? "badge bg-soft-success fs-13 mt-1 mx-1"
                        : jobDetails?.job_type?.toLowerCase() === "part time"
                        ? "badge bg-soft-danger fs-13 mt-1 mx-1"
                        : jobDetails?.job_type?.toLowerCase() === "freelancer"
                        ? "badge bg-soft-purple fs-13 mt-1 mx-1"
                        : "badge bg-soft-secondary fs-13 mt-1 mx-1"
                    }
                  >
                    {jobDetails?.job_type}
                  </span>

                  {jobDetails?.job_priority?.toLowerCase() === "high" && (
                    <span className={`badge bg-soft-warning fs-13 mt-1 me-1`}>
                      Urgent
                    </span>
                  )}

                  <span
                    className={
                      jobDetails?.job_position?.toLowerCase() === "fresher"
                        ? "badge bg-soft-blue fs-13 mt-1 mx-1"
                        : jobDetails?.job_position?.toLowerCase() === "junior"
                        ? "badge bg-soft-pink fs-13 mt-1 mx-1"
                        : jobDetails?.job_position?.toLowerCase() === "senior"
                        ? "badge bg-soft-info fs-13 mt-1 mx-1"
                        : "badge bg-soft-pink fs-13 mt-1 mx-1"
                    }
                  >
                    {jobDetails?.job_position}
                  </span>

                  {(jobDetails?.badges || []).map((badgeInner, key) => (
                    <span
                      className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                      key={key}
                    >
                      {badgeInner.badgeName}
                    </span>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={3} className='align-self-center'>
              <ul className='list-inline mt-3 mb-0'>
                {/* <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                      <Link onClick={openModal} to="#" className="avatar-sm bg-soft-danger d-inline-block text-center rounded-circle fs-18">
                         <i className="uil uil-trash-alt"></i>
                      </Link>
                    </li> */}

                {/********** View Applications Button **********/}
                <li
                  className='list-inline-item position-relative'
                  title='Post Jobs'
                  style={{ marginRight: 18 }}
                >
                  {/* <div className="my-badge">
                    <span>2</span>
                  </div> */}
                  <Link
                    to={`/employer/job/application/manage/${
                      jobDetails?.id
                    }/${convertToSlug(jobDetails?.job_title)}`}
                    className='btn btn-primary'
                  >
                    View Applications
                  </Link>
                </li>

                {/* Edit Button */}
                <li
                  className='list-inline-item'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Edit'
                >
                  <Link
                    to={`/employer/job/update/${jobDetails?.id}`}
                    state={{ job: jobDetails }}
                    className='avatar-sm bg-soft-primary d-inline-block text-center rounded-circle fs-18'
                  >
                    <i className='uil uil-edit'></i>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default JobItemRow;
