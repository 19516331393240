import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  Input,
  Label,
  Col,
  Row,
  FormFeedback,
  FormGroup,
  Spinner,
} from "reactstrap";
import { getCandidateExperiences } from "../../../../actions/customer";
import { createContent, updateContentsById } from "../../../../api/contents";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";
import MonthOptions from "../../../../helpers/MonthOptions";
import YearOptions from "../../../../helpers/YearOptions";

const ExperienceModal = ({
  openExperienceModal,
  setOpenExperienceModal,
  modalType = "add",
  experienceData,
}) => {
  const initialState = {
    position: "",
    company_name: "",
    start_year: "",
    start_month: "",
    end_year: "",
    end_month: "",
    details: "",
  };
  const additional_fields = experienceData?.additional_fields;

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const triggerModal = () => setOpenExperienceModal(!openExperienceModal);

  const { profile } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const onChangeValue = (e, value) => {
    setFormData({ ...formData, [value]: e.target.value });
  };

  const onChangeCheckbox = (e) => {
    setCurrentlyWorking(e.target.checked);
    setFormData({ ...formData, end_year: "", end_month: "" });
  };

  useEffect(() => {
    setFormData({
      position: additional_fields?.position || "",
      company_name: additional_fields?.company_name || "",
      start_year: additional_fields?.start_year || "",
      start_month: additional_fields?.start_month || "",
      end_year: additional_fields?.end_year || "",
      end_month: additional_fields?.end_month || "",
      details: additional_fields?.details || "",
    });

    setCurrentlyWorking(
      additional_fields?.is_currently_working === "Yes" ? true : false
    );
  }, [experienceData, additional_fields]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData?.position) {
      setErrors({ position: "This is required field" });
      return;
    }

    if (!formData?.company_name) {
      setErrors({ company_name: "This is required field" });
      return;
    }

    if (!formData?.start_month) {
      setErrors({ start_month: "This is required field" });
      return;
    }

    if (!formData?.start_year) {
      setErrors({ start_year: "This is required field" });
      return;
    }

    if (!currentlyWorking && !formData?.end_month) {
      setErrors({ end_month: "This is required field" });
      return;
    }

    if (!currentlyWorking && !formData?.end_year) {
      setErrors({ end_year: "This is required field" });
      return;
    }

    if (!currentlyWorking && formData?.start_year > formData?.end_year) {
      setErrors({
        end_year: "The end year can't be earlier than the start year.",
      });
      return;
    }

    if (!formData?.details) {
      setErrors({ details: "This is required field" });
      return;
    }

    setErrors(null);

    setLoading(true);

    const payload = {
      type: "candidate_experiences",
      AdditionalFields: {
        ...formData,
        candidate_id: profile?.id?.toString(),
        is_currently_working: currentlyWorking ? "Yes" : "No",
      },
    };

    if (modalType === "add") {
      createContent(payload)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.id) {
            triggerModal();
            CustomSnackbar.success("Your work experience added successfully.");
            setFormData(initialState);
            setCurrentlyWorking(false);

            dispatch(getCandidateExperiences(profile.id));
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    } else {
      updateContentsById(experienceData?.id, payload)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.id) {
            triggerModal();
            CustomSnackbar.success(
              "Your work experience updated successfully."
            );
            setFormData(initialState);

            dispatch(getCandidateExperiences(profile.id));
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={openExperienceModal} toggle={triggerModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {modalType === "add" ? "Add" : "Update"} Work Experience
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={triggerModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <Label for="positionControlInput" className="form-label">
                    Position *
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="positionControlInput"
                    placeholder="Enter your position or title"
                    value={formData.position}
                    onChange={(e) => onChangeValue(e, "position")}
                    invalid={errors?.position ? true : false}
                  />
                  <FormFeedback>{errors?.position}</FormFeedback>
                </div>

                <div className="mb-3">
                  <Label for="companyControlInput2" className="form-label">
                    Company *
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="companyControlInput2"
                    placeholder="Enter company name"
                    value={formData.company_name}
                    onChange={(e) => onChangeValue(e, "company_name")}
                    invalid={errors?.company_name ? true : false}
                  />
                  <FormFeedback>{errors?.company_name}</FormFeedback>
                </div>

                <div className="mb-3">
                  <Label for="select" className="form-label">
                    Start *
                  </Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="select"
                        className="form-select"
                        data-trigger
                        name="choices-start-month"
                        id="choices-start-month"
                        aria-label="Default select example"
                        value={formData?.start_month}
                        onChange={(e) => onChangeValue(e, "start_month")}
                        invalid={errors?.start_month ? true : false}
                      >
                        <MonthOptions />
                      </Input>
                      <FormFeedback>{errors?.start_month}</FormFeedback>
                    </Col>

                    <Col md={6}>
                      <Input
                        type="select"
                        className="form-select"
                        data-trigger
                        name="choices-start-year"
                        id="choices-start-year"
                        aria-label="Default select example"
                        value={formData?.start_year}
                        onChange={(e) => onChangeValue(e, "start_year")}
                        invalid={errors?.start_year ? true : false}
                      >
                        <YearOptions />
                      </Input>
                      <FormFeedback>{errors?.start_year}</FormFeedback>
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Label for="select" className="form-label">
                    End *
                  </Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="select"
                        className="form-select"
                        data-trigger
                        name="choices-end-month"
                        id="choices-end-month"
                        aria-label="Default select example"
                        value={formData?.end_month}
                        onChange={(e) => onChangeValue(e, "end_month")}
                        invalid={errors?.end_month ? true : false}
                        disabled={currentlyWorking}
                      >
                        <MonthOptions />
                      </Input>
                      <FormFeedback>{errors?.end_month}</FormFeedback>
                    </Col>

                    <Col md={6}>
                      <Input
                        type="select"
                        className="form-select"
                        data-trigger
                        name="choices-end-year"
                        id="choices-end-year"
                        aria-label="Default select example"
                        value={formData?.end_year}
                        onChange={(e) => onChangeValue(e, "end_year")}
                        invalid={errors?.end_year ? true : false}
                        disabled={currentlyWorking}
                      >
                        <YearOptions />
                      </Input>
                      <FormFeedback>{errors?.end_year}</FormFeedback>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={12}>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          id="work-checkbox"
                          onChange={onChangeCheckbox}
                          checked={currentlyWorking}
                        />
                        <Label check htmlFor="work-checkbox">
                          I'm currently working here
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Details *
                  </Label>
                  <Input
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Describe your work experience"
                    value={formData.details}
                    onChange={(e) => onChangeValue(e, "details")}
                    type="textarea"
                    invalid={errors?.details ? true : false}
                  />
                  <FormFeedback>{errors?.details}</FormFeedback>
                </div>

                <div className="text-end">
                  <button
                    type="button"
                    onClick={triggerModal}
                    className="btn btn-danger me-2 "
                  >
                    Cancel
                  </button>
                  {loading ? (
                    <button type="button" className="btn btn-primary disabled">
                      Save <Spinner size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  )}
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ExperienceModal;
