import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "reactstrap";
import { changePassword } from "../../../../api/customer";
import PasswordInput from "../../../../helpers/Common/PasswordInput";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (newPassword.length < 8) {
      CustomSnackbar.error("Password must be at least 8 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      CustomSnackbar.error("New password and confirm password does not match");
      return;
    }

    setLoading(true);

    changePassword({ new_password: newPassword })
      .then((res) => {
        if (res.status === 200) {
          CustomSnackbar.success("Password updated successfully");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          CustomSnackbar.error(err.response.data.message);
        } else {
          CustomSnackbar.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="mt-2">
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <PasswordInput
                label="New password *"
                name="new-password-input"
                value={newPassword}
                placeholder="Enter Current password"
                id="new-password-input"
                required={true}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </Col>

          <Col lg={12}>
            <div className="mb-3">
              <PasswordInput
                label="Confirm New Password *"
                name="confirm-password-input"
                value={confirmPassword}
                placeholder="Enter Current password"
                id="confirm-password-input"
                required={true}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <div className="mt-4 text-end">
          {loading ? (
            <button type="button" className="btn btn-primary disabled w-100">
              Update <Spinner size="sm" />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary w-100">
              Update
            </button>
          )}
        </div>
      </div>
    </Form>
  );
};

export default ChangePassword;
