import ajax from './ajax';

export function signUp(params) {
  return ajax('/customers', { method: 'POST', data: params });
}

export function login(params) {
  return ajax('/customers/login', { method: 'POST', data: params });
}

export function updateProfile(params) {
  return ajax(`/customers/update_customer`, { method: 'POST', data: params });
}

export function getCustomerDetails(userId) {
  return ajax(`/customers/${userId}`);
}

export function getCustomerDetailsByParams(params) {
  return ajax(`/customers?${params}`);
}

export function forgetPassword(params) {
  return ajax('/customers/forgot_password', { method: 'POST', data: params });
}

export function verifyEmailOtp(params) {
  return ajax('/customers/verify_email_otp', { method: 'POST', data: params });
}

export function changePassword(params) {
  return ajax('/customers/change_password', { method: 'POST', data: params });
}

export function getCustomerList(customerType, params = '') {
  return ajax(`customers?CustomersSearch[customer_type]=${customerType}${params}`);
}