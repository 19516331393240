import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './Routes/stores';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { authRoutes, authProtectedRoutes, commonRoutes } from './Routes/app-routes';
import PrivateRoute from './Routes/PrivateRoute';
import App from './App';

//import Custom Style scss
import "./assets/scss/themes.scss";
import AuthLayout from './Layout/AuthLayout';

const Home = lazy(() => import('./pages/Home'));
const Error404 = lazy(() => import('./pages/ExtraPages/Error404'));

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
};

ReactDOM.render(
  <React.Fragment>
    <Suspense fallback={Loader()}>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path='/' element={<App/>}>
              <Route index element={<Home />} />

              {commonRoutes.map((route) => (
                <Route
                  path={route.path}
                  element={route.element}
                  key={route.path}
                />
              ))}
            </Route>

           <Route element={<AuthLayout/>}>
              {authRoutes.map((route) => (
                <Route
                  path={route.path}
                  element={route.element}
                  key={route.path}
                />
              ))}
           </Route>

            <Route element={<App/>}>
              {authProtectedRoutes.map((route) => (
                <Route
                  path={route.path}
                  element={
                    <PrivateRoute>
                      {route.element}
                    </PrivateRoute>
                  }
                  key={route.path}
                />
              ))}
            </Route>

            <Route path="*" element={<Error404 />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </Suspense>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
