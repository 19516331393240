import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getJobsApplications } from "../../../../actions/employer";
import {
  convertToSlug,
  getCompanyLogo,
  getJobLocationById,
  getUserPic,
} from "../../../../helpers/common";
import { RejectButton } from "./RejectButton";
import { ShortListButton } from "./ShortListButton";

const ApplicationItem = ({ data }) => {
  const customer = useSelector((state) => state.customer.profile);
  const locations = useSelector((state) => state.jobLocations.data) || [];

  const dispatch = useDispatch();

  const invalidateApi = () => {
    dispatch(getJobsApplications(customer.id));
  };

  const jobDetails = data?.job;

  // Getting company details
  const companyDetails = data?.company;

  const candidateDetails = data?.candidate;
  const candidateAdditionalDetails = data?.candidate_additional_fields;

  // Application Status
  const applicationStatus = data?.status;

  // Candidate detail page URL
  const candidateDetailUrl = `/candidate/details/${candidateDetails?.id}`;

  // Job detail page URL
  const jobDetailsUrl = `/job/details/${jobDetails?.id}/${convertToSlug(
    jobDetails?.job_title
  )}`;

  return (
    <>
      <Card className='job-box card mt-4'>
        <CardBody className='p-4'>
          {/* Company Row */}
          <Row>
            <Col lg={1}>
              <Link to={jobDetailsUrl}>
                <img
                  src={getCompanyLogo(companyDetails.logo)}
                  alt={companyDetails?.name}
                  className='img-fluid rounded-3 comp-logo'
                />
              </Link>
            </Col>

            <Col lg={11} className='d-flex justify-content-between'>
              <div className='mt-3 mt-lg-0 '>
                <h5 className='fs-17 mb-1'>
                  <Link to={jobDetailsUrl} className='text-dark'>
                    {jobDetails?.job_title}
                  </Link>
                </h5>

                <ul className='list-inline mb-0'>
                  <li className='list-inline-item d-flex'>
                    <p className='text-muted fs-14 mb-0'>
                      <i className='mdi mdi-office-building me-1'></i>
                      {companyDetails?.name}
                    </p>
                  </li>
                </ul>
              </div>

              <div className='current_status'>
                Current Status:{" "}
                <span
                  className={`${
                    applicationStatus === "Pending"
                      ? "text-warning"
                      : applicationStatus === "Shortlisted"
                      ? "text-success"
                      : "text-danger"
                  } text-capitalize`}
                >
                  {applicationStatus}
                </span>
              </div>
            </Col>
          </Row>

          <div className='cust-divider'></div>

          {/* Candidate Row */}
          <Row>
            <Col lg={1}>
              <Link to={candidateDetailUrl}>
                <img
                  src={getUserPic(candidateAdditionalDetails?.profile_picture)}
                  alt={candidateDetails?.name}
                  className='avatar-md img-thumbnail rounded-circle'
                />
                {candidateDetails.photo}
              </Link>
            </Col>

            <Col lg={8}>
              <div className='mt-3 mt-lg-0 '>
                <h5 className='fs-17 mb-2'>
                  <Link to={candidateDetailUrl} className='text-dark'>
                    {candidateDetails?.name || "Unknown"}
                  </Link>
                </h5>

                <ul className='list-inline mb-0 text-muted'>
                  {candidateAdditionalDetails?.designation && (
                    <li className='list-inline-item'>
                      <i className='uil uil-bag'></i>{" "}
                      {candidateAdditionalDetails?.designation}
                    </li>
                  )}

                  <li className='list-inline-item'>
                    <i className='mdi mdi-map-marker'></i>{" "}
                    {getJobLocationById(
                      candidateAdditionalDetails?.location,
                      locations
                    )}
                  </li>
                  {candidateAdditionalDetails?.expected_salary_per_month && (
                    <li className='list-inline-item'>
                      <i className='uil uil-wallet'></i> ₹
                      {candidateAdditionalDetails?.expected_salary_per_month} /
                      month
                    </li>
                  )}
                </ul>
              </div>
            </Col>

            {applicationStatus === "Pending" && (
              <Col
                lg={3}
                className='d-flex justify-content-lg-end align-items-center mt-3 mt-lg-0'
              >
                <li
                  className='list-inline-item position-relative me-2'
                  title='Reject'
                >
                  <RejectButton data={data} invalidateApi={invalidateApi} />
                </li>

                <li
                  className='list-inline-item position-relative'
                  title='Post Jobs'
                >
                  <ShortListButton data={data} invalidateApi={invalidateApi} />
                </li>
              </Col>
            )}
          </Row>

          {data?.message && (
            <>
              <div className='cust-divider'></div>

              <p className='text-muted mb-0' style={{ fontSize: 14 }}>
                <span className='text-black'>Candidate Message:</span>{" "}
                {data?.message}
              </p>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ApplicationItem;
