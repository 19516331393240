import React from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const PricingPage = () => {
    const pricingpage = [
        {
            id: 1,
            pricingIcon: "uim-telegram-alt",
            pricingName: "Starter",
            pricingPrice: "₹350",
            pricingsuccessclass: "btn-soft-primary",
            pricingDetails: [
                {
                    id: 1,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Starter Feature 1"
                },
                {
                    id: 2,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Starter Feature 2"
                },
                {
                    id: 3,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Starter Feature 3"
                },
                {
                    id: 4,
                    pricingInnerClassName: "pricing-item text-decoration-line-through",
                    pricingInnerIcon: "mdi mdi-close-thick bg-soft-muted me-2",
                    pricingInnerText: "Starter Feature 4"
                },
                {
                    id: 5,
                    pricingInnerClassName: "pricing-item text-decoration-line-through",
                    pricingInnerIcon: "mdi mdi-close-thick bg-soft-muted me-2",
                    pricingInnerText: "Starter Feature 5"
                },
                {
                    id: 6,
                    pricingInnerClassName: "pricing-item text-decoration-line-through",
                    pricingInnerIcon: "mdi mdi-close-thick bg-soft-muted me-2",
                    pricingInnerText: "Starter Feature 6"
                },
            ]

        },
        {
            id: 2,
            pricingIcon: "uim-rocket",
            pricingName: "Professional",
            pricingPrice: "₹490",
            pricingsuccessclass: "btn-primary",
            pricingDetails: [
                {
                    id: 1,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Professional Feature 1"
                },
                {
                    id: 2,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Professional Feature 2"
                },
                {
                    id: 3,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Professional Feature 3"
                },
                {
                    id: 4,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Professional Feature 4"
                },
                {
                    id: 5,
                    pricingInnerClassName: "pricing-item text-decoration-line-through",
                    pricingInnerIcon: "mdi mdi-close-thick bg-soft-muted me-2",
                    pricingInnerText: "Professional Feature 5"
                },
                {
                    id: 6,
                    pricingInnerClassName: "pricing-item text-decoration-line-through",
                    pricingInnerIcon: "mdi mdi-close-thick bg-soft-muted me-2",
                    pricingInnerText: "Professional Feature 6"
                },
            ]

        },
        {
            id: 3,
            pricingIcon: "uim:bag",
            pricingName: "Enterprice",
            pricingPrice: "₹590",
            pricingsuccessclass: "btn-soft-primary",
            pricingDetails: [
                {
                    id: 1,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Enterprice Feature 1"
                },
                {
                    id: 2,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Enterprice Feature 2"
                },
                {
                    id: 3,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Enterprice Feature 3"
                },
                {
                    id: 4,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Enterprice Feature 4"
                },
                {
                    id: 5,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Enterprice Feature 5"
                },
                {
                    id: 6,
                    pricingInnerClassName: "pricing-item",
                    pricingInnerIcon: "mdi mdi-check-bold bg-soft-success me-2",
                    pricingInnerText: "Enterprice Feature 6"
                },
            ]

        }
    ];
    return (
        <React.Fragment>
            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center">
                                <span className="badge bg-soft-warning  fs-15 mb-2">Choose Your Plan</span>
                                <h3>Save up to 15%</h3>
                                <p className="text-muted">The faster, most seamless CI & development you'll find anywhere.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {(pricingpage).map((pricingpageDetails, key) => (
                            <Col lg={4} md={6} className="mt-5 pt-2" key={key}>
                                <Card className="pricing-box bg-light">
                                    <CardBody className="p-4 px-lg-5">
                                        <div className="pricing-icon bg-light rounded-circle icons-md">
                                            <Icon icon={pricingpageDetails.pricingIcon} className="text-primary" />
                                        </div>
                                        <div className="pricing-name text-center mt-4 pt-2">
                                            <h4 className="fs-18">{pricingpageDetails.pricingName}</h4>
                                        </div>
                                        <div className="pricing-price text-center mt-4">
                                            <h2 className="fw-semibold">{pricingpageDetails.pricingPrice}<small className="fs-16">/mo</small></h2>
                                        </div>
                                        <ul className="list-unstyled pricing-details text-muted mt-4">
                                            {(pricingpageDetails.pricingDetails || []).map((pricingpageInnerDetails, key) => (
                                                <li key={key} className={pricingpageInnerDetails.pricingInnerClassName}><i className={pricingpageInnerDetails.pricingInnerIcon}></i> {pricingpageInnerDetails.pricingInnerText}</li>
                                            ))}
                                        </ul>
                                        <div className="text-center mt-4 mb-2">
                                            <Link to="#" className={`btn ${pricingpageDetails.pricingsuccessclass} rounded-pill`}>Purchase Now <i className="uil uil-arrow-right"></i></Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default PricingPage;
