import React, { useEffect, useState } from 'react';
import { Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import StateOptions from '../../../../helpers/StateOptions';

const AddMoreDetails = ({ formData, setFormData, onChangeValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const [isPresentAddressSame, setIsPresentAddressSame] = useState(false);

  useEffect(() => {
    if (isPresentAddressSame) {

      setFormData((formData) => ({
        ...formData,
        present_address: formData.permanent_address,
        same_as_permanent_address: 'Yes'
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        same_as_permanent_address: 'No'
      }));

    }
  }, [isPresentAddressSame, setFormData])

  useEffect(() => {
    if (formData.same_as_permanent_address === 'Yes') {
      setIsPresentAddressSame(true)
    }
  }, [formData])

  return (
    <div className='w-100'>

      <div className='m-auto text-end'>
        <button
          className="btn btn-link my-link-btn text-underline"
          type="button"
          onClick={toggle}
        >
          {isOpen ? 'Hide more details' : 'Show more details'}
        </button>

      </div>

      <Collapse isOpen={isOpen}>
        <Row className="mt-2">


        <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="languages" className="form-label">
                  Languages Known
                </Label>
                <Input
                  data-score={2}
                  type="text"
                  className="form-control"
                  id="languages"
                  placeholder="e.g. English, Hindi, Urdu"
                  value={formData.languages_known}
                  onChange={(e) => onChangeValue(e, "languages_known")}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="skills" className="form-label">
                  Skills
                </Label>
                <Input
                  data-score={2}
                  type="text"
                  className="form-control"
                  id="skills"
                  placeholder="Enter your skills comma seperated"
                  value={formData.skills}
                  onChange={(e) => onChangeValue(e, "skills")}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className="mb-3">
                <Label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Introduce Yourself
                </Label>
                <textarea
                  className="form-control"
                  rows="7"
                  data-score={2}
                  placeholder="Introduce Yourself"
                  value={formData.details}
                  onChange={(e) => onChangeValue(e, "details")}
                ></textarea>
              </div>
            </Col>



          <Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="hobbies" className="form-label">
                Hobbies
              </Label>
              <textarea
                className="form-control"
                rows="3"
                data-score={2}
                id="hobbies"
                placeholder="Enter your hobbies line seperated"
                value={formData.hobbies}
                onChange={(e) => onChangeValue(e, "hobbies")}
              ></textarea>

            </div>
          </Col>

          <Col lg={12}>
            <div className="mb-3">
              <Label
                htmlFor="permanent_address"
                className="form-label"
              >
                Permanent Address
              </Label>
              <textarea
                className="form-control"
                rows="3"
                data-score={2}
                id="permanent_address"
                placeholder="Permanent Address"
                value={formData.permanent_address}
                onChange={(e) => onChangeValue(e, "permanent_address")}
              ></textarea>
            </div>
          </Col>

          <Col lg={12}>
            <div className="mb-3">
              <Label
                htmlFor="present_address"
                className="form-label"
              >
                Present Address
              </Label>
              <textarea
                className="form-control"
                rows="3"
                data-score={2}
                id="present_address"
                placeholder="Present Address"
                value={formData.present_address}
                disabled={isPresentAddressSame}
                onChange={(e) => onChangeValue(e, "present_address")}
              ></textarea>
            </div>
          </Col>

          <Col md={12} className="mb-3">
            <FormGroup check inline>
              <Input
                type="checkbox"
                id="work-checkbox"
                onChange={(event) => setIsPresentAddressSame(event.target.checked)}
                checked={isPresentAddressSame}
              />
              <Label check htmlFor="work-checkbox">
                Same as parmanent address
              </Label>
            </FormGroup>
          </Col>

          <Col lg={12}>
            <div className="mb-3">
              <Label
                htmlFor="choices-single-state"
                className="form-label"
              >
               Your State
              </Label>
              <select
                className="form-select"
                data-trigger
                data-score={2}
                name="choices-single-state"
                id="choices-single-state"
                aria-label="Default select example"
                value={formData.state}
                onChange={(e) => onChangeValue(e, "state")}
              >
                <StateOptions />
              </select>
            </div>
          </Col>
        </Row>

        <div className="mt-3">
          <div className="cust-divider"></div>

          <h5 className="fs-17 fw-semibold mb-3">Social Media</h5>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="facebook" className="form-label">
                  Facebook
                </Label>
                <Input
                  data-score={1}
                  type="text"
                  className="form-control"
                  id="facebook"
                  placeholder="Enter your facebook url"
                  value={formData.facebook_url}
                  onChange={(e) => onChangeValue(e, "facebook_url")}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="twitter" className="form-label">
                  Twitter
                </Label>
                <Input
                  data-score={1}
                  type="text"
                  className="form-control"
                  id="twitter"
                  placeholder="Enter your twitter url"
                  value={formData.twitter_url}
                  onChange={(e) => onChangeValue(e, "twitter_url")}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="linkedin" className="form-label">
                  Linkedin
                </Label>
                <Input
                  data-score={1}
                  type="text"
                  className="form-control"
                  id="linkedin"
                  placeholder="Enter your linkedin url"
                  value={formData.linkedin_url}
                  onChange={(e) => onChangeValue(e, "linkedin_url")}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="whatsapp" className="form-label">
                  Whatsapp
                </Label>
                <Input
                  data-score={1}
                  type="text"
                  className="form-control"
                  id="whatsapp"
                  placeholder="Enter your whatsapp number"
                  to="https://www.whatsapp.com"
                  value={formData.whatsapp_number}
                  onChange={(e) => onChangeValue(e, "whatsapp_number")}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>


    </div>
  )
}

export default AddMoreDetails