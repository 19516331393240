import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getEmployerCompanies } from "../../../../../actions/employer";
import { updateCompany } from "../../../../../api/companies";
import { apiErrorHandler } from "../../../../../helpers/common";
import { CustomSnackbar } from "../../../../../helpers/CustomSnackbar";
import ManageCompanyForm from "../ManageCompanyForm";

const EditFormSec = () => {
  const location = useLocation();
  const companyDetails = location?.state?.companyDetails;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const customer = useSelector((state) => state.customer.profile);
  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    setLoading(true);

    updateCompany(companyDetails.id, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch(getEmployerCompanies(customer?.id));
          CustomSnackbar.success(
            "Your company details has been updated successfully."
          );
          navigate(-1);
        }
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="bg-soft-primary p-3">
              <h5 className="mb-0 fs-17">Edit Company Details!</h5>
            </div>
          </Col>
        </Row>

        <ManageCompanyForm
          loading={loading}
          onSubmit={onSubmit}
          companyDetails={companyDetails}
          formType="update"
        />
      </Container>
    </React.Fragment>
  );
};

export default EditFormSec;
