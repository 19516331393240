import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

//Import Images
// import AboutImage from "../../../assets/images/about/img-01.jpg";
import { dataUrl, getContentByName, toArray } from '../../../helpers/common';
import parse from 'html-react-parser';

const About = () => {

    const assets = useSelector(state => state.banners['assets'])
    const partialContents = useSelector(state => state.contents['partial_contents']);

    const aboutusHeading = getContentByName(partialContents, 'aboutus_heading');
    const aboutusDescription = getContentByName(partialContents, 'aboutus_description');
    const aboutusLeftPoints = getContentByName(partialContents, 'aboutus_left_points');
    const aboutusRightPoints = getContentByName(partialContents, 'aboutus_right_points');
    const aboutusLeftPointsArray = toArray(aboutusLeftPoints?.content, '\n');
    const aboutusRightPointsArray = toArray(aboutusRightPoints?.content, '\n');

    const aboutusImg = assets?.find(asste => asste.name === 'About Us Image');

    return (
        <React.Fragment>
            <section className="section overflow-hidden">
                <Container>
                    <Row className="align-items-center g-0">
                        <Col lg={6}>
                            <div className="section-title me-lg-5">
                                <h6 className="sub-title">About Us</h6>
                                <h2 className='title mb-4'>
                                    {parse(aboutusHeading?.content?.replace(/&nbsp;/gi, ' ') ?? '')}
                                </h2>

                                <span className="text-muted">{parse(aboutusDescription?.content?.replace(/&nbsp;/gi, ' ') ?? '')}</span>

                                <Row mt={4} pt={2}>
                                    <Col md={6}>
                                        <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                                            {aboutusLeftPointsArray.map((point, index) => (
                                                <li key={index}> {parse(point ?? '')}</li>
                                            ))}
                                        </ul>
                                    </Col>
                                    <Col md={6}>
                                        <ul className="list-unstyled about-list text-muted">
                                            {aboutusRightPointsArray.map((point, index) => (
                                                <li key={index}> {parse(point ?? '')}</li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                                <div className="mt-3">
                                    <Link to="/pages/about-us-full" className="btn btn-primary btn-hover">Learn More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="about-img mt-4 mt-lg-0">
                               {aboutusImg && <img src={`${dataUrl}/banners/${aboutusImg?.image}`} alt="" className="img-fluid rounded" />}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default About;
