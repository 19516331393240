import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const AuthLayout = () => {
  return (
    <React.Fragment>
      <Outlet />
      <ToastContainer draggable={true} theme="colored" />
    </React.Fragment>
  );
};

export default AuthLayout;